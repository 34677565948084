<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref, computed, watchEffect } from "vue";
import CodeInput from "@/components/ui-elements/CodeInput.vue";
import { useMainStore } from "@/stores/main";
import { useDiscountStore } from "@/stores/discount";
import { useI18n } from "vue-i18n";
import { DiscountType } from "@/stores/discount";

const { t } = useI18n();
const mainStore = useMainStore();
const discountStore = useDiscountStore();

const {
  error,
  isValid,
  isValidating,
  value,
  valueType,
  symbol,
  valueInAmount,
  code: storeCode,
} = storeToRefs(discountStore);

const code = ref("");

watchEffect(() => {
  code.value = storeCode.value;
});

const successMessage = computed(() => {
  const stringUpdate = { percentagediscount: valueInAmount.value, symbol: symbol.value };
  if (valueType.value === DiscountType.PERCENTAGE && value.value === -100) {
    return t("VOUCHER.CODE.FREE_PRODUCTS", stringUpdate);
  } else {
    return t("VOUCHER.CODE.VALID", stringUpdate);
  }
});

const validateCode = () => {
  discountStore.validate(code.value, mainStore.model, mainStore.storeCountry);
};

const clearInput = () => {
  discountStore.$reset();
  code.value = "";
};
</script>

<template>
  <div class="code-title">{{ t("DISCOUNT.CODE.TITLE") }}</div>
  <CodeInput
    @validate="validateCode"
    :modelValue="code"
    @update:modelValue="code = $event"
    @clear="clearInput"
    :isValidating="isValidating"
    :placeholderText="t('DISCOUNT.CODE.PLACEHOLDER')"
    :isValid="isValid"
    :error="{
      active: error,
      msg: t('VOUCHER.CODE_INVALID'),
    }"
  >
    <template v-if="isValid" v-slot:success>
      <img class="code-valid-icon" src="../assets/util/checkmark-circle-filled.svg" alt="checkmark-circle" />
      <span>{{ successMessage }}</span>
    </template>
  </CodeInput>
</template>

<style scoped>
@import "@/assets/base.css";

.code-title {
  margin-bottom: 0.5em;
  font-size: 1.3em;
  font-weight: bold;
  text-align: left;
}
.code-valid-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
}
</style>
