import BaseView from "../views/BaseView.vue";
import ContactDetails from "@/components/ContactDetails.vue";
import DeliveryDetails from "@/components/DeliveryDetails.vue";
import PaymentOptions from "@/components/PaymentOptions.vue";
import { shopifyRedirectGuard } from "./guards";
import { initializeLocationData } from "@/util/set-location-settings";
import { setModel } from "@/util/set-model";
import { setDiscount } from "@/util/set-discount";
import { initializeGoogleServices } from "@/services/GooglePredictions";

const routes = [
  {
    path: "/",
    name: "base",
    component: BaseView,
    beforeEnter: [shopifyRedirectGuard, initializeLocationData, setModel, setDiscount, initializeGoogleServices],
    children: [
      {
        path: "/",
        name: "contact-details",
        component: ContactDetails,
        meta: {
          navIndex: 0,
        },
      },
      {
        path: "delivery-details",
        name: "delivery-details",
        component: DeliveryDetails,
        meta: {
          navIndex: 1,
          requires: ["CD"],
        },
      },
      {
        path: "payment-options",
        name: "payment-options",
        component: PaymentOptions,
        meta: {
          navIndex: 2,
          requires: ["CD", "DD"],
        },
      },
    ],
  },
  {
    path: "/payment-failed",
    name: "payment-failed",
    beforeEnter: [initializeLocationData, setModel, shopifyRedirectGuard],
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/PaymentFailed.vue"),
  },
  {
    path: "/payment-success",
    name: "payment-success",
    beforeEnter: [initializeLocationData, setModel, shopifyRedirectGuard],
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/PaymentSuccess.vue"),
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (About.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import("../views/AboutView.vue"),
  // },
];

export { routes };
