import { createRouter, createWebHistory } from "vue-router";
import { formsGuard } from "./guards";
import { routes } from "./routes";

declare module "vue-router" {
  interface RouteMeta {
    navIndex: number;
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(formsGuard);

export default router;
