<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { RouterLink } from "vue-router";
import { steps } from "@/router/navigation-steps";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const goToPreviousStep = () => {
  if (route.meta.navIndex === 0) return;

  const routeName = steps[route.meta.navIndex - 1].routeName;
  router.push({ name: routeName, query: { ...route.query } });
};
</script>

<template>
  <nav>
    <div class="logo-wrapper">
      <div v-if="route.meta.navIndex > 0" @click="goToPreviousStep" class="arrow left icon-button">
        <img alt="arrow" src="../../assets/util/black_arrow.svg" />
      </div>
      <a href="https://uk.vaha.com" target="_blank" rel="noopener" class="logo icon-button">
        <img src="../../assets/vaha_logo_black.svg" alt="vaha-logo" />
      </a>
    </div>
    <div class="steps">
      <div class="step" v-for="(step, index) in steps" :key="index">
        <div class="index-container">
          <RouterLink
            :to="{ name: step.routeName, query: { ...route.query } }"
            :class="['index', route.meta.navIndex <= index && 'circle']"
          >
            <img alt="checkmark" v-if="route.meta.navIndex > index" src="../../assets/util/checkmark-circle.svg" />
            <span v-if="route.meta.navIndex <= index">{{ index + 1 }}</span>
          </RouterLink>
        </div>
        <div :class="{ name: true, bolded: route.meta.navIndex === index }">{{ t(step.name) }}</div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
@import "@/assets/base.css";

a.logo,
a.logo img {
  height: 46px;
  width: 213px;
}
a.logo {
  margin: 30px 20px 20px 20px !important ;
}
@media (--max-tablet-width) {
  a.logo {
    margin: 20px 0 10px 0 !important;
  }
  a.logo,
  a.logo img {
    height: 23px;
    width: 107px;
    flex: 1;
  }
}

nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  position: relative;
}
.logo-wrapper .arrow {
  position: absolute;
  cursor: pointer;
  transform: rotate(90deg);
  left: 0;
  top: 40px;
}
.logo-wrapper .arrow,
.logo-wrapper .arrow img {
  width: 2vw;
  height: 2vw;
  flex: 0 0 2vw;
  min-width: 15px;
  min-height: 15px;
  max-width: 25px;
  max-height: 25px;
  margin: 0;
}

.steps {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 90%;
}
.steps .step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  z-index: 9;
  width: 220px;
}
.steps .step .bolded {
  font-weight: 700;
}
.steps .step .index-container {
  background-color: white;
}
.steps .step .index-container a {
  text-decoration: none;
}
.steps .step .index-container span {
  font-weight: 700;
}
.steps .step .index {
  width: 35px;
  height: 35px;
  margin: 13px 25px 12px 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.steps .step .index.circle {
  height: 35px;
  width: 35px;
  border: 1.5px solid var(--color-classy-yellow);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: white;
  color: var(--color-classy-yellow);
  font-size: 16.85px;
  font-weight: 700;
  padding: 10px;
}
.steps .step .index.circle.router-link-exact-active {
  border-color: var(--color-classy-yellow);
  background-color: var(--color-classy-yellow);
  color: white;
}
.steps .step .name {
  /* font-size: 17px; */
  color: rgba(0, 0, 0, 0.87);
}
.steps::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 32.5px;
  width: calc(100% - 220px);
  height: 1px;
  background-color: var(--color-medium-grey);
  z-index: 0;
}

@media (--max-tablet-width) {
  nav {
    margin-bottom: 20px;
  }
  .logo-wrapper {
    width: 100%;
  }
  .logo-wrapper .arrow {
    top: 26px;
    left: 2em;
  }
  .steps .step {
    width: 134px;
  }
  .steps .index {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
    margin: 15px 25px 0 25px !important;
  }
  .steps::after {
    top: 28.5px;
    width: calc(100% - 110px);
  }
}
</style>
