import { useMainStore } from "@/stores/main";
import { Country, Locale, StoreCountry } from "@/util/constants";

export const initializeLocationData = (to: any, _from: any, next: any) => {
  const store = useMainStore();
  const countryParam = (to.query.country || Country.DE).toLowerCase();

  if (countryParam === Country.DE) {
    store.changeCountry(Country.DE);
    store.changeStoreCountry(StoreCountry.EU);
    store.changeLocale(Locale.DE);
  } else if (countryParam === Country.AT) {
    store.changeCountry(Country.AT);
    store.changeStoreCountry(StoreCountry.EU);
    store.changeLocale(Locale.DE);
  } else if (countryParam === Country.UK) {
    store.changeCountry(Country.UK);
    store.changeStoreCountry(StoreCountry.UK);
    store.changeLocale(Locale.EN);
  }
  next();
};
