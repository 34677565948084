import { ShopifyRedirectsConfig } from "@/util/shopify-redirects";
import { useMainStore } from "@/stores/main";
import { useContactDetailsStore } from "@/stores/contact-details";
import { useDeliveryDetailsStore } from "@/stores/delivery-details";

export const shopifyRedirectGuard = async (to: any, _from: any, next: any) => {
  const store = useMainStore();
  const [err, _done] = await ShopifyRedirectsConfig.download(import.meta.env.VITE_SHOPIFY_REDIRECTS_CONFIG);
  if (err) {
    store.changeAppLoading(false);
    store.changeMajorError(true);
    return next(false);
  }

  const redirectUrl = ShopifyRedirectsConfig.checkIfShouldRedirectAndToWhere(to.query);
  if (redirectUrl) {
    next(false);
    return (window.location.href = redirectUrl);
  }

  store.changeAppLoading(false);
  next(true);
};

export const formsGuard = (to: any) => {
  if (!to.meta.requires) return;

  for (const req of to.meta.requires) {
    if (req === "CD") {
      const cdStore = useContactDetailsStore();
      if (!cdStore.isCompleted) {
        return { name: "contact-details", query: { ...to.query } };
      } else {
        continue;
      }
    }
    if (req === "DD") {
      const ddStore = useDeliveryDetailsStore();
      if (!ddStore.isCompleted) {
        return { name: "delivery-details", query: { ...to.query } };
      } else {
        continue;
      }
    }
  }

  return;
};
