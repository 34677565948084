export enum VahaModelParam {
  VAHA_X = "vaha-x",
  VAHA_S = "vaha-s",
}
export enum VahaModel {
  VAHA_X = "VAHA_X",
  VAHA_S = "VAHA_S",
}

export enum VahaModelLower {
  VAHA_X = "vaha_x",
  VAHA_S = "vaha_s",
}
export enum VahaModelSubmitParam {
  VAHA_X = "vaha_x_hardware_subscription",
  VAHA_S = "vaha_s_hardware_subscription",
}

export enum Country {
  UK = "uk",
  DE = "de",
  AT = "at",
}
export const AVAILABLE_COUNTRIES: Country[] = [Country.DE, Country.AT, Country.UK];
export const CountryFullName: { [key in Country]: string } = {
  uk: "United Kingdom",
  de: "Germany",
  at: "Austria",
};
export enum StoreCountry {
  EU = "eu",
  UK = "uk",
}
export const AVAILABLE_STORES: StoreCountry[] = [StoreCountry.EU, StoreCountry.UK];
export enum Locale {
  EN = "en",
  DE = "de",
}
export const AVAILABLE_LOCALES: Locale[] = [Locale.EN, Locale.DE];

export const PAYMENT_TOTAL: { [key in StoreCountry]: { [key in VahaModel]: number } } = {
  eu: {
    VAHA_X: 249,
    VAHA_S: 199,
  },
  uk: {
    VAHA_X: 249,
    VAHA_S: 199,
  },
};

export const SUCCESS_VIDEO_URLS: { [key in Country]: string[] } = {
  uk: [
    "https://www.youtube.com/embed/gqa1zRlCR6I",
    "https://www.youtube.com/embed/hGWb35HJx_4",
    "https://www.youtube.com/embed/bV0DUnk5Ll8",
    "https://www.youtube.com/embed/vKTq1A9_fko",
  ],
  de: [
    "https://www.youtube.com/embed/cbsCx6kljtg",
    "https://www.youtube.com/embed/_W9bg-PRdKY",
    "https://www.youtube.com/embed/ti_EhHlN2SM",
    "https://www.youtube.com/embed/3yVStTV1X1Y",
  ],
  at: [
    "https://www.youtube.com/embed/cbsCx6kljtg",
    "https://www.youtube.com/embed/_W9bg-PRdKY",
    "https://www.youtube.com/embed/ti_EhHlN2SM",
    "https://www.youtube.com/embed/3yVStTV1X1Y",
  ],
};

export const LINKS: { [key in Country]: { [key: string]: string } } = {
  at: {
    privacy: "https://at.vaha.com/datenschutz",
    conditions: "https://at.vaha.com/anb",
    terms: "https://at.vaha.com/akb",
    imprint: "https://at.vaha.com/impressum",
    klarna: "https://de.vaha.com/klarna-terms",
    website: "https://at.vaha.com",
    community: "https://www.facebook.com/groups/vahaflow",
    magazine: "https://magazine.vaha.com/de/",
    youtube: "https://www.youtube.com/channel/UCgm6UcoqtL9uHYwGKJ3EJOg",
    facebook: "https://www.facebook.com/groups/vahaflow",
  },
  de: {
    privacy: "https://de.vaha.com/datenschutz",
    conditions: "https://de.vaha.com/anb",
    terms: "https://de.vaha.com/akb",
    imprint: "https://de.vaha.com/impressum",
    klarna: "https://de.vaha.com/klarna-terms",
    website: "https://de.vaha.com",
    community: "https://www.facebook.com/groups/vahaflow",
    magazine: "https://magazine.vaha.com/de/",
    youtube: "https://www.youtube.com/channel/UCgm6UcoqtL9uHYwGKJ3EJOg",
    facebook: "https://www.facebook.com/groups/vahaflow",
  },
  uk: {
    privacy: "https://uk.vaha.com/data-protection",
    conditions: "https://uk.vaha.com/terms-and-conditions",
    terms: "https://uk.vaha.com/general-purchase-conditions",
    imprint: "https://uk.vaha.com/imprint",
    klarna: "https://uk.vaha.com/klarna-terms",
    website: "https://uk.vaha.com",
    community: "https://www.facebook.com/groups/vahaflowuk",
    magazine: "https://magazine.vaha.com",
    youtube: "https://www.youtube.com/watch?v=O4kNNAs11xk&list=PLDU6E__zMTlH0BwM-Fo3VaCV3Ag3OdqB8",
    facebook: "https://www.facebook.com/groups/vahaflowuk",
  },
};
