<script setup lang="ts">
import IconSpinner from "@/components/icons/IconSpinner.vue";

defineProps<{
  modelValue: string;
  placeholderText: string;
  isValidating: boolean;
  isValid: boolean;
  error: { active: boolean; msg: string };
}>();
const emit = defineEmits<{
  (e: "validate"): void;
  (e: "update:modelValue", code: string): void;
  (e: "clear"): void;
}>();

const emitChangeCode = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;

  if (!value) emit("clear");
  emit("update:modelValue", value);
};
</script>

<template>
  <div class="code-input">
    <div class="box">
      <input
        type="text"
        :placeholder="placeholderText"
        :value="modelValue"
        @input="emitChangeCode"
        @keyup.enter="$emit('validate')"
      />
      <div class="icon-cont">
        <IconSpinner v-if="isValidating" />
        <img
          v-else-if="isValid"
          class="close-icon"
          src="../../assets/util/close.svg"
          alt="remove-code"
          @click="$emit('clear')"
        />
        <img v-else src="../../assets/util/boxed_arrow.svg" alt="validate" @click="$emit('validate')" />
      </div>
    </div>
    <transition name="slide" appear>
      <div v-if="error.active" class="message error">{{ error.msg }}</div>
    </transition>
    <transition name="slide" appear>
      <div v-if="isValid" class="message success">
        <slot name="success" />
      </div>
    </transition>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
.code-input {
  background-color: var(--color-lighter-grey);
  border: 1px solid var(--color-stone-grey);
  margin-bottom: 2em;
  position: relative;
}
.box {
  display: flex;
  justify-content: space-between;
  padding: 2.5em 1.5em 2.5em 1.5em;
}
.code-input input {
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: none;
  border: none;
  padding-left: 12px;
  height: 27px;
  border-bottom: 1px solid var(--color-medium-dark-grey);
  outline: none;
  font-size: 1.1em;
  width: 80%;
  line-height: 1.5rem;
  padding: 0 1em 0 1em;
}
.code-input .icon-cont {
  cursor: pointer;
  display: table;
}
.code-input .icon-cont img {
  width: 30px;
  height: 30px;
  display: table-row;
}
.code-input .message {
  font-size: 0.9em;
  position: absolute;
  bottom: 1em;
  left: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  text-align: left;
  max-width: 75%;
}
.code-input .message.error {
  color: var(--color-error-red);
}
.code-input .message.success {
  color: var(--color-success-green);
}
.code-input .icon-cont .close-icon {
  filter: invert(0.3);
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s linear;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-1em);
}
.slide-enter-to,
.slide-leave-from {
  transform: translateY(0em);
  opacity: 1;
}

@media (--max-laptop-width) {
  .code-input .message {
    bottom: 0.8em;
  }
}
</style>
