<script setup lang="ts">
import { ref, computed, watch } from "vue";

// ----- Props & Emits -----
const props = defineProps<{
  modelValue: string;
  labelText: string;
  inputName: string;
  errored?: boolean;
  suggestion?: string;
  dontAutocomplete?: boolean;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: "update:modelValue", code: string): void;
}>();
// ----- Props & Emits -----

const initialFocus = props.modelValue ? true : false;

// ----- Data -----
const focused = ref(initialFocus);
const textInput = ref(null);
defineExpose({ textInput });
// ----- Data -----

// ----- Watchers -----
watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) focused.value = true;
  }
);
// ----- Watchers -----

// -----Computed-----
const suggestionHiddenText = computed<string>(() => {
  if (!props.suggestion) return "";
  return props.suggestion.substring(0, props.modelValue.length);
});
const suggestionVisibleText = computed<string>(() => {
  if (!props.suggestion) return "";
  return props.suggestion.substring(props.modelValue.length);
});
// -----Computed-----

// ----- Methods -----
const emitUpdate = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  emit("update:modelValue", value);
};

const focusInput = (event: Event) => {
  if (props.modelValue) return;

  if (event.target) {
    focused.value = event.target === document.activeElement;
  } else {
    focused.value = !focused.value;
  }
};
// ----- Methods -----
</script>

<template>
  <div class="text-input">
    <label :for="inputName" :class="{ 'moved-up': focused, error: errored, disabled: disabled }">{{ labelText }}</label>
    <input
      :id="inputName"
      type="email"
      :class="{ error: errored }"
      :name="inputName"
      :value="modelValue"
      @input="emitUpdate"
      @focus="focusInput"
      @blur="focusInput"
      :autocomplete="dontAutocomplete ? 'off' : 'on'"
      ref="textInput"
      :disabled="disabled"
    />
    <div class="suggestion" v-if="suggestion">
      <span class="hidden">{{ suggestionHiddenText }}</span>
      <span class="visible">{{ suggestionVisibleText }}</span>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
.text-input {
  overflow-x: hidden;
  overflow-y: visible;
  padding-top: 1em;
  position: relative;
}

.input-cont {
  position: relative;
  overflow: hidden;
}
.suggestion {
  font-size: 1.3em;
  line-height: 1.5rem;
  position: absolute;
  bottom: 7px;
  pointer-events: none;
  margin-left: 10px;
  max-height: 1.5rem;
  overflow: hidden;
  text-align: left;
}
.suggestion .hidden {
  opacity: 0;
}
.suggestion .visible {
  color: var(--color-medium-darkish-grey);
}

label {
  color: var(--color-medium-dark-grey);
  font-size: 1.1em;
  position: absolute;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
  transform-origin: top left;
  pointer-events: none;
}

label.moved-up {
  transform: scale(0.8) translateY(-1.5em);
}

label.error {
  color: var(--color-error-red);
}
label.disabled {
  color: var(--color-light-grey);
}
label.error.disabled {
  color: var(--color-error-light-red);
}

input {
  min-height: 1.8em;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: none;
  border: none;
  padding-left: 12px;
  height: 27px;
  border-bottom: 1px solid var(--color-medium-dark-grey);
  outline: none;
  font-size: 1.3em;
  width: 100%;
  line-height: 1.5rem;
}
input:autofill,
input:autofill:hover,
input:autofill:focus {
  box-shadow: 0 0 0px 100px white inset;
}
input:disabled {
  cursor: not-allowed;
  border-bottom: 1px solid var(--color-light-grey);
}

input.error {
  border-bottom: 1px solid var(--color-error-red);
}
input:disabled.error {
  border-bottom: 1px solid var(--color-error-light-red);
}
</style>
