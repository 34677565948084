<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref, computed } from "vue";
import CodeInput from "@/components/ui-elements/CodeInput.vue";
import { useReferralStore } from "@/stores/referral";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const referralStore = useReferralStore();

const { error, isValid, isValidating, symbol, value } = storeToRefs(referralStore);

const code = ref("");

const successMessage = computed(() => {
  const stringUpdate = { value: value.value, symbol: symbol.value };
  return t("REFERRAL.CODE.VALID", stringUpdate);
});

const validateCode = () => {
  referralStore.validate(code.value);
};

const clearInput = () => {
  referralStore.$reset();
  code.value = "";
};
</script>

<template>
  <div class="code-title">{{ t("REFERRAL.CODE.TITLE") }}</div>
  <CodeInput
    @validate="validateCode"
    :modelValue="code"
    @update:modelValue="code = $event"
    @clear="clearInput"
    :isValidating="isValidating"
    :placeholderText="t('REFERRAL.CODE.PLACEHOLDER')"
    :isValid="isValid"
    :error="{
      active: error,
      msg: t('REFERRAL.CODE_INVALID'),
    }"
  >
    <template v-if="isValid" v-slot:success>
      <img class="code-valid-icon" src="../assets/util/checkmark-circle-filled.svg" alt="checkmark-circle" />
      <span>{{ successMessage }}</span>
    </template>
  </CodeInput>
</template>

<style scoped>
@import "@/assets/base.css";

.code-title {
  margin-bottom: 0.5em;
  font-size: 1.3em;
  font-weight: bold;
  text-align: left;
}
.code-valid-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5em;
}
</style>
