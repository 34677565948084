export enum ShippingType {
  STANDARD = "standard",
  PREMIUM = "premium",
}

export type ShippingZone = {
  cost: number;
  id: number;
  shippingType: ShippingType;
  shopifyShippingTitle: string;
};
