export enum Gender {
  FEMALE = "female",
  MALE = "male",
}

export type CDInputErrors = {
  gender: boolean;
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
};

export type ContactDetails = {
  gender: Gender;
  firstName: string;
  lastName: string;
  phoneNumber: {
    value: string;
    countryCode: string;
    isValid: boolean;
  };
  email: string;
};

export type DDInputErrors = {
  deliveryAddress: {
    streetCity: boolean;
    houseNo: boolean;
    postalCode: boolean;
  };
  invoiceAddress: {
    streetCity: boolean;
    houseNo: boolean;
    postalCode: boolean;
  };
};

export type DeliveryData = {
  deliveryAddress: {
    streetCity: string;
    postalCode: string;
    houseNo: string;
    city: string;
    street: string;
    countryCode: string;
    additionalInfo: string;
    firstSuggestion: any;
  };
  invoiceAddress: {
    streetCity: string;
    postalCode: string;
    houseNo: string;
    city: string;
    street: string;
    countryCode: string;
    firstSuggestion: any;
  };
  sameAddress: boolean;
};
