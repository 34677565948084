import { defineStore } from "pinia";
import type { VahaModel } from "@/util/constants";
import { StoreCountry, PAYMENT_TOTAL } from "@/util/constants";
import { API } from "@/util/api_paths";
import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import { useMainStore } from "@/stores/main";

export enum DiscountType {
  PERCENTAGE = "percentage",
  FIXED_AMOUNT = "fixed_amount",
}

export const useDiscountStore = defineStore({
  id: "discount",
  state: () => ({
    code: "",
    value: 0,
    valueType: DiscountType.FIXED_AMOUNT,
    products: [],
    performedValidation: false,
    isValidating: false,
    error: false,
  }),
  getters: {
    valueInAmount(): number {
      if (this.valueType === DiscountType.PERCENTAGE) return (this.fullPrice * this.value) / 100;
      return this.value;
    },
    fullPrice(): number {
      const mainStore = useMainStore();
      const storeCountry: StoreCountry = mainStore.storeCountry;
      const model: VahaModel = mainStore.model;
      return PAYMENT_TOTAL[storeCountry][model];
    },
    price(): number {
      const discount = this.valueType === DiscountType.PERCENTAGE ? (this.fullPrice * this.value) / 100 : this.value;
      return this.fullPrice - discount;
    },
    isValid(): boolean {
      return this.performedValidation && !!this.code;
    },
    symbol(): "€" | "£" {
      const mainStore = useMainStore();
      return mainStore.storeCountry === StoreCountry.EU ? "€" : "£";
    },
  },
  actions: {
    async validate(value: string, selectedModel: VahaModel, store: StoreCountry) {
      this.$reset(); // * This will reset the store to it's initial value
      this.isValidating = true;

      try {
        const data = await axios.get(
          `${API.VOUCHER}?discount_code=${encodeURIComponent(
            value
          )}&vaha_model=${selectedModel.toLowerCase()}&vaha_store_country=${store}`
        );
        const camelData = camelcaseKeys(data, { deep: true });
        const processedData = this.createDiscount(camelData.data);
        if (this.isDataValid(processedData)) this.applyVoucher(processedData);
      } catch (err) {
        console.error("Error validating discount code, ", err);
        this.error = true;
      } finally {
        this.isValidating = false;
      }
    },

    createDiscount(data: any) {
      return {
        code: data.code,
        valueType: data.priceRule.valueType,
        value: data.priceRule.value,
        startsAt: data.priceRule.startsAt,
        products: data.products,
      };
    },

    isDataValid(voucherResult: any) {
      return (
        (voucherResult.valueType === DiscountType.FIXED_AMOUNT ||
          voucherResult.valueType === DiscountType.PERCENTAGE) &&
        (voucherResult.startsAt ? new Date(voucherResult.startsAt).getTime() <= new Date().getTime() : false)
      );
    },

    applyVoucher(discount: any) {
      this.performedValidation = true;
      this.error = false;
      this.value = Math.abs(parseInt(discount.value));
      this.code = discount.code;
      this.valueType = discount.valueType;
      if (discount.products) this.products = discount.products;
    },
  },
});
