<script setup lang="ts">
import { onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import ButtonMain from "@/components/ui-elements/ButtonMain.vue";
import CheckboxInput from "@/components/ui-elements/CheckboxInput.vue";
import { useDeliveryDetailsStore } from "@/stores/delivery-details";

defineEmits<{
  (e: "goNext"): void;
}>();

const DDStore = useDeliveryDetailsStore();
const { copyright } = storeToRefs(DDStore);

const { t } = useI18n();

onUnmounted(() => {
  DDStore.changeCopyrightError(false);
});
</script>

<template>
  <div class="delivery-bottom">
    <div class="copyright-cont">
      <CheckboxInput
        :value="copyright.selected"
        :errored="copyright.errored"
        @emitClick="DDStore.changeCopyrightTermsSelected"
      />
      <div class="copyright-text">{{ t("DELIVERY_DETAILS.COPYRIGHT.TEXT") }}</div>
    </div>
    <div v-if="copyright.errored" class="copyright-error">
      <img src="../assets/util/error.svg" alt="error-icon" />
      <div class="error-msg">{{ t("DELIVERY_DETAILS.COPYRIGHT.ERROR.TEXT") }}</div>
    </div>
    <ButtonMain @emitClick="$emit('goNext')">
      <template v-slot:button-text>{{ t("DELIVERY_DETAILS.BUTTON.TEXT") }}</template>
    </ButtonMain>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.copyright-cont {
  display: flex;
  margin-bottom: 3em;
}
.copyright-error {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}
.copyright-error img {
  width: 25px;
  height: 25px;
  margin-right: 1em;
}
.copyright-error .error-msg {
  color: var(--color-error-red);
  font-size: 1.2em;
}
</style>
