import axios from "axios";

const ShopifyRedirectsConfig = {
  data: null as any,

  async download(path: string) {
    try {
      const res = await axios.get(path);
      this.data = res.data;
      return [null, true];
    } catch (err) {
      console.log(`Error trying to fetch from ${path}`, err);
      return [err, null];
    }
  },

  checkIfShouldRedirectAndToWhere(routeQuery: any) {
    if (routeQuery.country === "uk") return;
    if (!this.data) return;

    let model = "vaha-x";
    let country = "de";
    let redirectLink = "";

    if (routeQuery.model) model = routeQuery.model;
    if (routeQuery.country) country = routeQuery.country;

    const withoutLine = model.replace("-", "");
    const lastLetter = withoutLine[withoutLine.length - 1].toUpperCase();
    const modelParsed = withoutLine.replace(withoutLine[withoutLine.length - 1], lastLetter);

    try {
      redirectLink = `${this.data.shopifyLinks[country][modelParsed]}?`;
    } catch (err) {
      console.error("No such entry in the json config file", err);
      return;
    }

    for (const [key, value] of Object.entries(routeQuery)) {
      if (key === "discount") {
        redirectLink += `discount=${value}&`;
        continue;
      }
      redirectLink += `${key}=${value}&`;
    }

    return redirectLink;
  },
};

export { ShopifyRedirectsConfig };
