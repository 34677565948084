<script setup lang="ts">
import { ref } from "vue";

const showItems = ref(true);
</script>
<template>
  <div class="row collapseable-group">
    <div class="row flex-out title-cont">
      <div class="title-img" @click="showItems = !showItems">
        <div class="bold title"><slot name="title" /></div>
        <img src="../../assets/util/left-arrow.svg" :class="['icon-arrow', { show: showItems }]" alt="arrow" />
      </div>
    </div>
    <transition name="slide" appear>
      <div class="items" v-if="showItems">
        <slot name="items" />
      </div>
    </transition>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
.row {
  margin: 0.5em 0 0.5em 0;
}
.flex-out {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-cont {
  margin-bottom: 1em;
}
.title-img {
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1;
}
.title {
  margin-right: 1em;
  font-size: 1.1rem;
  letter-spacing: 0.05em;
}
.icon-arrow {
  width: 20px;
  height: 20px;
  transition: transform 0.2s;
  transform: rotate(-90deg);
}
.icon-arrow.show {
  transform: rotate(90deg);
}
.items {
  z-index: 0;
}
</style>
