import { markRaw, createApp } from "vue";
import { createPinia } from "pinia";
/* eslint-disable-next-line */
// @ts-ignore
import { fetchDefaultLocale } from "../i18n-setup";
import VueGtag from "vue-gtag";

import App from "./App.vue";
import router from "./router";
import "maz-ui/css/main.css";

fetchDefaultLocale().then((i18n: any) => {
  const app = createApp(App);
  const pinia = createPinia();

  pinia.use(({ store }) => {
    store.router = markRaw(router);
  });

  app.use(pinia);
  app.use(router);
  app.use(i18n);
  if (import.meta.env.VITE_NODE_ENV === "production") {
    app.use(
      VueGtag,
      {
        config: {
          id: import.meta.env.VITE_GOOGLE_ANALYTICS_KEY,
        },
      },
      router
    );
  }

  app.mount("#app");
});
