import { defineStore } from "pinia";
import { type Country, StoreCountry, Locale, VahaModel } from "@/util/constants";

declare module "pinia" {
  export interface PiniaCustomProperties {
    router: any;
  }
}

export const useMainStore = defineStore({
  id: "main",
  state: () => ({
    appLoading: true,
    country: "de" as Country,
    storeCountry: StoreCountry.EU as StoreCountry,
    locale: Locale.DE as Locale,
    model: VahaModel.VAHA_X,
    majorError: {
      active: false,
      templates: {
        shopifyConfig: {
          title: ":-(",
          text: "Something went wrong. Please try again later",
        },
      },
    },
  }),
  getters: {},
  actions: {
    changeCountry(value: Country) {
      this.country = value;
    },
    changeStoreCountry(value: StoreCountry) {
      this.storeCountry = value;
    },
    changeLocale(value: Locale) {
      this.locale = value;
    },
    changeModel(value: VahaModel) {
      this.model = value;
    },
    changeAppLoading(value: boolean) {
      this.appLoading = value;
    },
    changeMajorError(value: boolean) {
      this.majorError.active = value;
    },
    updateModel(model: VahaModel) {
      this.model = model;
    },
  },
});
