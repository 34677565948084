<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { reactive, computed, ref, onBeforeMount } from "vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import DiscountCode from "@/components/DiscountCode.vue";
import ReferralCode from "@/components/ReferralCode.vue";
import TextInput from "./ui-elements/TextInput.vue";
import { onBeforeRouteLeave } from "vue-router";
import { Gender } from "@/types/FormTypes";
import type { ContactDetails, CDInputErrors } from "@/types/FormTypes";
import { validateContactDetails } from "@/util/form-validations";
import { useContactDetailsStore } from "@/stores/contact-details";
import { useMainStore } from "@/stores/main";
import { Country } from "@/util/constants";

const { t } = useI18n();
const contactDetailsStore = useContactDetailsStore();
const mainStore = useMainStore();
const { contactDetails, isCompleted } = contactDetailsStore;
const { country } = mainStore;

// ----- Data -----
const contactDetailsForm: ContactDetails = reactive({
  gender: Gender.FEMALE,
  firstName: "",
  lastName: "",
  phoneNumber: {
    value: "",
    countryCode: "",
    isValid: false,
  },
  email: "",
});

const customerTriedToMoveOnAtLeastOnce = ref(false);
// ----- Data -----

// ----- Hooks -----
onBeforeMount(() => {
  if (!isCompleted) return;

  contactDetailsForm.gender = contactDetails.gender;
  contactDetailsForm.firstName = contactDetails.firstName;
  contactDetailsForm.lastName = contactDetails.lastName;
  contactDetailsForm.email = contactDetails.email;
  contactDetailsForm.phoneNumber = contactDetails.phoneNumber;
});
// ----- Hooks -----

// ----- Computed -----
const validationErrors = computed<CDInputErrors>(() => {
  return validateContactDetails(contactDetailsForm);
});

const prefferedCountryCodes = computed<any>(() => {
  const countries = Object.values(Country);
  const weights = countries.map((val) => {
    return {
      country: val,
      weight: val === country ? 1 : 0,
    };
  });
  const countriesForPhoneComponent = weights
    .sort((a, b) => a.weight + b.weight)
    .map((val) => {
      const newVal = val.country === Country.UK ? "gb" : val.country;
      return newVal.toUpperCase();
    });

  if (import.meta.env.MODE === "development") countriesForPhoneComponent.push("RO");

  return countriesForPhoneComponent;
});

const defaultCountryCode = computed<any>(() => {
  return country === Country.UK ? "GB" : country.toUpperCase();
});
// ----- Computed -----

// -----Methods -----
const updatePhoneNumber = (event: any) => {
  contactDetailsForm.phoneNumber.value = event;
  contactDetailsForm.phoneNumber.countryCode = event.countryCode;
  contactDetailsForm.phoneNumber.value = event.formatInternational;
  contactDetailsForm.phoneNumber.isValid = event.isValid;
};

const isInputErrored = (val: boolean) => {
  return val && customerTriedToMoveOnAtLeastOnce.value;
};
// -----Methods -----

// ----- Guards -----
onBeforeRouteLeave((_to, _from, next) => {
  customerTriedToMoveOnAtLeastOnce.value = true;

  const shouldMoveOn = Object.values(validationErrors.value).every((val: boolean) => !val);
  if (shouldMoveOn) contactDetailsStore.updateContactDetails(contactDetailsForm);
  next(shouldMoveOn);
});
// ----- Guards -----
</script>

<template>
  <div class="contact-details">
    <span class="page-title">{{ t("CONTACT_DETAILS.TITLE") }}</span>
    <form>
      <div class="gender-cont">
        <div class="gender-option">
          <input type="radio" id="mrs" :value="Gender.FEMALE" v-model="contactDetailsForm.gender" />
          <label for="mrs">{{ t("CONTACT_DETAILS.GENDER.FEMALE") }}</label>
        </div>
        <div class="gender-option">
          <input type="radio" id="mr" :value="Gender.MALE" v-model="contactDetailsForm.gender" />
          <label for="mr">{{ t("CONTACT_DETAILS.GENDER.MALE") }}</label>
        </div>
      </div>

      <div class="names-cont">
        <div class="name-option">
          <TextInput
            v-model="contactDetailsForm.firstName"
            :labelText="t('CONTACT_DETAILS.LABELS.FIRST_NAME')"
            :inputName="'first-name'"
            :errored="isInputErrored(validationErrors.firstName)"
          />
        </div>
        <div class="name-option">
          <TextInput
            v-model="contactDetailsForm.lastName"
            :labelText="t('CONTACT_DETAILS.LABELS.LAST_NAME')"
            :inputName="'last-name'"
            :errored="isInputErrored(validationErrors.lastName)"
          />
        </div>
      </div>

      <div class="phone-number">
        <MazPhoneNumberInput
          :class="isInputErrored(validationErrors.phoneNumber) ? 'phone-number-error' : ''"
          v-model="contactDetailsForm.phoneNumber.value"
          show-code-on-list
          color="success"
          :preferred-countries="prefferedCountryCodes"
          :ignored-countries="['AC']"
          @update="updatePhoneNumber"
          :success="contactDetailsForm.phoneNumber.isValid"
          placeholder="Mobile phone number"
          :no-example="true"
          :default-country-code="defaultCountryCode"
          :translations="{
            countrySelector: { placeholder: '', error: '' },
            phoneInput: { placeholder: '', example: '' },
          }"
        />
      </div>

      <div class="email">
        <TextInput
          v-model="contactDetailsForm.email"
          :labelText="t('CONTACT_DETAILS.LABELS.EMAIL')"
          :inputName="'email'"
          :errored="isInputErrored(validationErrors.email)"
        />
      </div>

      <ReferralCode />
      <DiscountCode />
    </form>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
.page-title {
  font-size: 1.8rem;
  font-weight: 700;
  display: block;
  text-align: left;
  margin-bottom: 1em;
}
.gender-cont {
  font-size: 1.3em;
  display: flex;
  margin-bottom: 2em;
}
.gender-option {
  margin: 0.5em;
  display: flex;
  cursor: pointer;
}
.gender-cont label {
  padding-left: 1em;
}
.gender-cont input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid var(--color-classy-yellow);
  border-radius: 50%;
  background-clip: content-box;
  width: 25px;
  height: 25px;
  padding: 5px;
  cursor: pointer;
}
.gender-option:first-child {
  margin-right: 2em;
}
.gender-cont input[type="radio"]:checked {
  background-color: var(--color-classy-yellow);
}
.gender-cont label {
  cursor: inherit;
}

.names-cont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2em;
  margin-bottom: 2em;
}
.names-cont .name-option,
.email {
  position: relative;
}
.phone-number {
  width: 100%;
  margin-bottom: 2em;
}
.phone-number :deep(.m-input-wrapper) {
  border: none;
  border-bottom: 1px solid var(--color-medium-dark-grey);
  border-color: unset !important;
  border-radius: 0;
}
.phone-number :deep(input) {
  font-size: 1.3em !important;
}
.phone-number :deep(.m-select-list) {
  border-radius: 0;
  margin-top: 0.3em;
}
.phone-number :deep(.m-select-list::-webkit-scrollbar) {
  border-radius: 5px;
  background: transparent;
  width: 0px;
}
.phone-number :deep(.m-select-list button:hover) {
  background-color: var(--color-light-classy-yellow);
}
.phone-number :deep(.m-select-list .--is-selected) {
  background-color: var(--color-light-classy-yellow);
  color: inherit;
}
.phone-number :deep(.m-input-wrapper-input) {
  font-size: 1.1em;
  color: var(--color-medium-dark-grey);
}
.phone-number :deep(.m-phone-number-input__country-flag) {
  bottom: 18px;
}
.phone-number :deep(.m-select.m-phone-number-input__select) {
  width: 8rem;
}
.phone-number-error :deep(.m-input-wrapper) {
  border-bottom: 1px solid var(--color-error-red) !important;
}
.phone-number-error :deep(.m-input-wrapper-input) {
  color: var(--color-error-red) !important;
}

.phone-number :deep(.m-phone-number-input__input):autofill,
.phone-number :deep(.m-phone-number-input__input):autofill:hover,
.phone-number :deep(.m-phone-number-input__input):autofill:focus {
  box-shadow: 0 0 0px 100px white inset;
}

.email {
  margin-bottom: 2em;
}
</style>
