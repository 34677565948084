import type { Country } from "@/util/constants";
import { useMainStore } from "@/stores/main";
import camelcaseKeys from "camelcase-keys";

declare const google: any;

export const GoogleAutocompleteService = {
  instance: null as any,
  country: "" as Country,

  instantiate(country: Country) {
    this.instance = new google.maps.places.AutocompleteService();
    this.country = country;
  },

  async getPlacePredictions(userInput: string) {
    if (!this.instance) return;

    try {
      const request = { input: userInput, componentRestrictions: { country: [this.country] }, types: ["address"] };
      const res = await this.instance.getPlacePredictions(request);
      const cameledRes = camelcaseKeys(res.predictions, { deep: true });
      return [null, cameledRes];
    } catch (err) {
      console.error("Error fetching Google Autocomplete Predictions", err);
      return [err, null];
    }
  },
};

export const GooglePlacesService = {
  instance: null as any,
  country: "" as Country,

  setCountry(country: Country) {
    this.country = country;
  },

  instantiate(el: HTMLElement) {
    this.instance = new google.maps.places.PlacesService(el);
  },

  async getPlace(placeId: string) {
    return new Promise((resolve) => {
      try {
        if (!this.instance) throw Error("No Instance");

        const request = { fields: ["address_components"], placeId };
        this.instance.getDetails(request, (res: any) => {
          const cameledRes = camelcaseKeys(res, { deep: true });
          return resolve([null, cameledRes]);
        });
      } catch (err) {
        console.error("Error fetching Google Place", err);
        return resolve([true, null]);
      }
    });
  },
};

export const initializeGoogleServices = () => {
  const store = useMainStore();
  GoogleAutocompleteService.instantiate(store.country);
  GooglePlacesService.setCountry(store.country);
};
