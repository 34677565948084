import { ref, reactive, onMounted, unref, watch } from "vue";
import { GoogleAutocompleteService, GooglePlacesService } from "@/services/GooglePredictions";

export function useGooglePredictions(el: any, data: any) {
  const address = reactive({
    streetCity: "",
    houseNo: "",
    postalCode: "",
    city: "",
    street: "",
    countryCode: "",
    firstSuggestion: null as any,
  });
  const predictions: any = reactive({
    autocompleteList: [],
    selectedSuggestionDescription: "",
  });
  const shippingAAs = reactive({
    administrativeAreaLevel_1Name: "",
    administrativeAreaLevel_2Name: "",
  });
  const htmlElement = ref(null);
  const shouldPredict = ref(true);

  onMounted(() => {
    htmlElement.value = unref(el);
    GooglePlacesService.instantiate((htmlElement.value as any).textInput);

    changeShouldPredict(true);
  });

  watch(
    () => data.streetCity,
    (val: string) => {
      if (!shouldPredict.value) return;
      updateStreetCity(val);
    }
  );

  watch(
    () => data.houseNo,
    (val: string) => {
      if (!shouldPredict.value) return;
      updateHouseNo(val);
    }
  );

  const resetAddress = () => {
    predictions.selectedSuggestionDescription = "";
    predictions.autocompleteList.length = 0;
    address.streetCity = "";
    address.firstSuggestion = null;
    address.houseNo = "";
    address.postalCode = "";
    address.city = "";
    address.street = "";
    address.countryCode = "";
    shippingAAs.administrativeAreaLevel_1Name = "";
    shippingAAs.administrativeAreaLevel_2Name = "";
  };

  const selectPrediction = async (pred: any) => {
    address.streetCity = pred.description;
    predictions.selectedSuggestionDescription = pred.description;
    address.firstSuggestion = "";

    (htmlElement.value as any).textInput.focus();
    predictions.autocompleteList = [];
  };

  const updateStreetCity = async (userInput: string) => {
    address.streetCity = userInput;
    address.houseNo = "";
    address.postalCode = "";
    address.city = "";
    address.street = "";
    address.countryCode = "";

    if (!userInput) return resetAddress();

    if (predictions.selectedSuggestionDescription === userInput) return; // * This means that the user clicked on a prediction and the streetCity input was populated with the suggestion

    const [err, list]: any = await GoogleAutocompleteService.getPlacePredictions(userInput);

    if (err) return;
    if (address.streetCity === predictions.selectedSuggestionDescription) return; // * If internet is slow and user types fast, selects smth from dropdown then we shouldn't show any more pending autocompletes
    if (!address.streetCity) return; // * This could happen if input is very fast (holding backspace on to delete)

    predictions.autocompleteList = list;
    address.firstSuggestion = list[0];
  };

  const updateHouseNo = async (userInput: string) => {
    if (!parseInt(userInput) || !address.streetCity) {
      address.houseNo = "";
      return;
    }

    address.houseNo = userInput;
    const [autoErr, list]: any = await GoogleAutocompleteService.getPlacePredictions(
      `${userInput} ${predictions.selectedSuggestionDescription}`
    );
    if (autoErr) return;

    const [placeErr, place]: any = await GooglePlacesService.getPlace(list[0].placeId);
    if (placeErr) return;

    updateAddressFieldsWithSuggestion(place.addressComponents);
    updateShippingZoneAreas(place.addressComponents);
  };

  const updateAddressFieldsWithSuggestion = (place: any) => {
    const googleStreetNr = place.find((el: any) => el.types.includes("street_number"))?.shortName || "";
    const googlePostalCode = place.find((el: any) => el.types.includes("postal_code"))?.shortName || "";
    const city =
      place.find((el: any) => el.types.includes("locality"))?.shortName ||
      place.find((el: any) => el.types.includes("postal_town"))?.shortName;
    ("");
    const street = place.find((el: any) => el.types.includes("route"))?.shortName || "";
    const countryCode = place.find((el: any) => el.types.includes("country"))?.shortName || "";
    address.houseNo = googleStreetNr;
    address.postalCode = googlePostalCode;
    address.city = city;
    address.street = street;
    address.countryCode = countryCode;
  };

  const updateShippingZoneAreas = (place: any) => {
    const admAreaLvl1Name = place.find((el: any) => el.types.includes("administrative_area_level_1"))?.longName || "";
    const admAreaLvl2Name = place.find((el: any) => el.types.includes("administrative_area_level_2"))?.longName || "";
    shippingAAs.administrativeAreaLevel_1Name = admAreaLvl1Name;
    shippingAAs.administrativeAreaLevel_2Name = admAreaLvl2Name;
  };

  const changeShouldPredict = (val: boolean) => {
    shouldPredict.value = val;
  };

  const autofill = (data: {
    streetCity: string;
    houseNo: string;
    postalCode: string;
    city: string;
    street: string;
    countryCode: string;
  }) => {
    address.streetCity = data.streetCity;
    address.houseNo = data.houseNo;
    address.postalCode = data.postalCode;
    address.city = data.city;
    address.street = data.street;
    address.countryCode = data.countryCode;
  };

  return { address, predictions, selectPrediction, changeShouldPredict, autofill, shippingAAs };
}
