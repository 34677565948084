import type { Order, ClientSessionRes, PaymentRes } from "@/types/PaymentTypes";
import { API } from "@/util/api_paths";
import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { Primer, PaymentMethodType } from "@primer-io/checkout-web";
import type { PaymentHandling, CardPreferredFlow } from "@primer-io/checkout-web";
import type { Locale } from "@/util/constants";

export type SelectedPaymentMethod = {
  PAYPAL: "PAYPAL";
  CARD: "PAYMENT_CARD";
};

const widgetStyle = {
  scene: {
    transition: false,
  },
  input: {
    base: {
      background: "white",
      height: 40,
      placeholder: {
        color: "#5f5f5f",
      },
    },
  },
  inputLabel: {
    fontSize: "16px",
    fontFamily: "inherit",
    letterSpacing: "0.15px",
  },
  inputErrorText: {
    color: "#f36214",
  },
};

const PrimerService = {
  checkoutOptions: null as any,
  paymentResponse: null as PaymentRes | null,
  clientSessionRes: null as ClientSessionRes | null,
  callbacks: {
    onSuccess: null as any,
    onError: null as any,
    onLoading: null as any,
  },
  submitPayment: null as any,
  selectedPaymentMethod: null as SelectedPaymentMethod | null,

  resetData() {
    this.checkoutOptions = null;
    this.paymentResponse = null;
    this.clientSessionRes = null;
    this.callbacks.onSuccess = null;
    this.callbacks.onError = null;
    this.callbacks.onLoading = null;
    this.submitPayment = null;
    this.selectedPaymentMethod = null;
  },

  setCallbacks(successCb: any, errorCb: any, onLoading: any) {
    this.callbacks.onSuccess = successCb;
    this.callbacks.onError = errorCb;
    this.callbacks.onLoading = onLoading;
  },

  setCheckoutOptions(container: string, locale: Locale) {
    this.checkoutOptions = {
      container,
      locale,
      paymentHandling: "MANUAL" as PaymentHandling,
      allowedPaymentMethods: [PaymentMethodType.PAYMENT_CARD, PaymentMethodType.PAYPAL],
      card: {
        preferredFlow: "EMBEDDED_IN_HOME" as CardPreferredFlow,
      },
      submitButton: {
        useBuiltInButton: false,
        onLoading: (isLoading: boolean) => {
          this.callbacks.onLoading(isLoading);
        },
      },

      onTokenizeSuccess: (data: any, handler: any) => {
        return this.onTokenizeSuccess(data, handler);
      },

      onResumeSuccess: (data: any, handler: any) => {
        return this.onResumeSuccess(data, handler);
      },

      onCheckoutFail: (error: any, data: any, handler: any) => {
        return this.onCheckoutFail({ error, data, handler });
      },

      style: widgetStyle,
    };
  },

  async postClientSession(order: Order) {
    const snakedOrder = snakecaseKeys(order);
    try {
      const res: any = await axios.post(`${API.CLIENT_SESSION}`, snakedOrder);
      this.clientSessionRes = camelcaseKeys(res.data, { deep: true }) as ClientSessionRes;
      return;
    } catch (err) {
      console.error("Error on /submit request, ", err);
      return;
    }
  },

  async postPayment(token: string) {
    if (!this.clientSessionRes) return;

    try {
      const payload = snakecaseKeys({
        paymentMethodToken: token,
        orderId: this.clientSessionRes.orderId,
      });
      const data = await axios.post<any>(`${API.PAYMENTS}`, payload);
      const result = camelcaseKeys(data, { deep: true });
      return [null, result.data];
    } catch (err) {
      console.error("Error trying to POST a payment, ", err);
      return [err, null];
    }
  },

  async resumePayment(resumeToken: string) {
    if (!this.clientSessionRes) return;

    try {
      const payload = snakecaseKeys({ resumeToken: resumeToken, orderId: this.clientSessionRes.orderId });
      const data = await axios.post<any>(API.PAYMENTS_RESUME, payload);
      const result = camelcaseKeys(data, { deep: true });
      return [null, result];
    } catch (err) {
      console.error("Error trying to Resume a payment, ", err);
      return [err, null];
    }
  },

  async onTokenizeSuccess(data: any, handler: any) {
    this.selectedPaymentMethod = data.paymentInstrumentType as SelectedPaymentMethod;

    const [err, res]: any = await this.postPayment(data.token);
    if (err) return this.callbacks.onError(this.selectedPaymentMethod);

    this.paymentResponse = res;
    if (res.requiredAction?.clientToken) {
      return handler.continueWithNewClientToken(res.requiredAction.clientToken);
    }

    return this.callbacks.onSuccess();
  },

  async onResumeSuccess(data: any, handler: any) {
    const [err, res]: any = await this.resumePayment(data.resumeToken);
    if (err) return this.callbacks.onError(this.selectedPaymentMethod);

    if (res.requiredAction?.clientToken) {
      return handler.continueWithNewClientToken(res.requiredAction.clientToken);
    }

    return this.callbacks.onSuccess();
  },

  onCheckoutFail(data: any) {
    console.error("Checkout Failed, ", data);
    return this.callbacks.onError();
  },

  async initializeSDK(container: string, locale: Locale) {
    if (!this.clientSessionRes?.clientToken) return;
    this.setCheckoutOptions(container, locale);

    try {
      const universalCheckout = await Primer.showUniversalCheckout(
        this.clientSessionRes.clientToken,
        this.checkoutOptions
      );
      const handleMySubmitButtonClick = (_e: any) => universalCheckout.submit();
      this.submitPayment = handleMySubmitButtonClick;
      return [false, true];
    } catch (err) {
      console.error("Error trying to initialize Primer SDK, ", err);
      return [err, false];
    }
  },
};

export default PrimerService;
