import { createI18n } from "vue-i18n";
import axios from "axios";

const DEFAULT_LOCALE = "en";
let i18n;

export const fetchDefaultLocale = async () => {
  const pathLang = `${import.meta.env.BASE_URL}lang/${DEFAULT_LOCALE}.json`;
  // const pathLang =
  //   process.env.NODE_ENV === "development"
  //     ? `./lang/${DEFAULT_LOCALE}.json`
  //     : `../lang/${DEFAULT_LOCALE}.json`;

  /**
   * * We request it via http, we don't import it as it could change via Webhook (when deployed)
   * * and if it's imported then it would have been bundled by Rollup with all files and it
   * * wouldn't have been changeable anymore.
   */
  try {
    const response = await axios.get(pathLang, {
      headers: {
        "content-type": "application/json",
      },
    });

    i18n = createI18n({
      locale: DEFAULT_LOCALE, // set locale
      fallbackLocale: DEFAULT_LOCALE,
      messages: { en: response.data }, // set locale messages
    });

    return i18n;
  } catch (error) {
    console.log("Error fetching default locale, ", error);
    return null;
  }
};

// const loadedLanguages = [DEFAULT_LOCALE]; // our default language that is preloaded

// function setI18nLanguage(lang) {
//   i18n.locale = lang;
//   axios.defaults.headers.common["Accept-Language"] = lang;
//   document.querySelector("html").setAttribute("lang", lang);
//   return lang;
// }

/*
export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  const pathLang =
    process.env.NODE_ENV === "development"
      ? `./lang/${lang}.json`
      : `../lang/${lang}.json`;
  // If the language hasn't been loaded yet

  axios
    .get(pathLang, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then((response) => {
      i18n.setLocaleMessage(lang, response.data);
      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    });
}
*/
