<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import ButtonMain from "@/components/ui-elements/ButtonMain.vue";
import { steps, RoutePath } from "@/router/navigation-steps";
import DeliveryDetailsBottom from "../DeliveryDetailsBottom.vue";
import { usePaymentStore } from "@/stores/payment";
import { storeToRefs } from "pinia";
import FreqAskQuestions from "@/components/ui-elements/FreqAskQuestions.vue";
import { initializeReviewsWidget } from "@/util/reviewsWidget";

const paymentStore = usePaymentStore();
const { loadingPayment } = storeToRefs(paymentStore);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const routePath = ref(route.path);

watchEffect(() => {
  // * For some reason Typescript errors if the newPath param is set as a RoutePath
  const path: string = route.path;
  routePath.value = path;

  if (routePath.value === RoutePath.CONTACT_DETAILS) initializeReviewsWidget();
});

const completePurchase = () => {
  paymentStore.submitPayment();
};

const goToNextStep = () => {
  if (route.meta.navIndex === 2) return completePurchase(); // TODO We will dispatch a Pinia action

  const nextRouteName = steps[route.meta.navIndex + 1].routeName;
  router.push({ name: nextRouteName, query: { ...route.query } });
};
</script>

<template>
  <div class="base-bottom">
    <ButtonMain v-if="routePath === RoutePath.CONTACT_DETAILS" @emitClick="goToNextStep">
      <template v-slot:button-text>{{ t("CONTACT_DETAILS.BUTTON.TEXT") }}</template>
    </ButtonMain>
    <DeliveryDetailsBottom v-else-if="routePath === RoutePath.DELIVERY_DETAILS" @goNext="goToNextStep" />
    <ButtonMain
      v-else-if="routePath === RoutePath.PAYMENT_DETAILS"
      @emitClick="goToNextStep"
      :disabled="loadingPayment"
      :loading="loadingPayment"
    >
      <template v-slot:button-text>{{ t("PAYMENT_DETAILS.BUTTON.TEXT") }}</template>
    </ButtonMain>

    <FreqAskQuestions v-if="routePath === RoutePath.CONTACT_DETAILS" />
    <div v-if="routePath === RoutePath.CONTACT_DETAILS" id="reviewsio-carousel-widget"></div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.base-bottom {
  width: 80%;
  margin: 0 auto 3em auto;
}

#reviewsio-carousel-widget {
  margin-top: 3em;
}
</style>
