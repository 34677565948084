export enum RoutePath {
  CONTACT_DETAILS = "/",
  DELIVERY_DETAILS = "/delivery-details",
  PAYMENT_DETAILS = "/payment-options",
}

export enum RouteName {
  CONTACT_DETAILS = "contact-details",
  DELIVERY_DETAILS = "delivery-details",
  PAYMENT_DETAILS = "payment-options",
}

type NavigationStep = {
  name: string;
  routeName: RouteName;
  routePath: RoutePath;
};

const steps: NavigationStep[] = [
  { name: "NAVIGATION.CONTACT_DETAILS", routeName: RouteName.CONTACT_DETAILS, routePath: RoutePath.CONTACT_DETAILS },
  { name: "NAVIGATION.DELIVERY_DETAILS", routeName: RouteName.DELIVERY_DETAILS, routePath: RoutePath.DELIVERY_DETAILS },
  { name: "NAVIGATION.PAYMENT_DETAILS", routeName: RouteName.PAYMENT_DETAILS, routePath: RoutePath.PAYMENT_DETAILS },
];

export { steps };
