import { defineStore } from "pinia";
import { useMainStore } from "@/stores/main";
import { StoreCountry } from "@/util/constants";
import { API } from "@/util/api_paths";
import axios from "axios";

export const useReferralStore = defineStore({
  id: "referral",
  state: () => ({
    code: "",
    value: 39,
    performedValidation: false,
    isValidating: false,
    error: false,
  }),
  getters: {
    symbol(): "€" | "£" {
      const mainStore = useMainStore();
      return mainStore.storeCountry === StoreCountry.EU ? "€" : "£";
    },
    isValid(): boolean {
      return !!this.code && this.performedValidation;
    },
  },
  actions: {
    async validate(code: string) {
      this.$reset();
      this.isValidating = true;

      try {
        await axios.get(API.REFERRAL_CODE.replace("{referralCode}", code));

        this.code = code;
        this.error = false;
        this.performedValidation = true;
      } catch (err) {
        console.error("Error validating referral code, ", err);
        this.error = true;
      } finally {
        this.isValidating = false;
      }
    },
  },
});
