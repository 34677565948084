import { useMainStore } from "@/stores/main";
import { VahaModel, VahaModelParam } from "@/util/constants";

export const setModel = (to: any, _from: any, next: any) => {
  const store = useMainStore();

  const model = to.query.model || VahaModelParam.VAHA_X;
  for (const [key, value] of Object.entries(VahaModelParam)) {
    if (model.toLowerCase() === value) {
      store.changeModel(VahaModel[key as VahaModel]);
      break;
    }
  }
  next();
};
