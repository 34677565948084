import { useDiscountStore } from "@/stores/discount";
import { useMainStore } from "@/stores/main";

export const setDiscount = (to: any, _from: any, next: any) => {
  const discountStore = useDiscountStore();
  const mainStore = useMainStore();

  const discountCode = to.query.discount;
  if (!discountCode) return next();

  discountStore.validate(discountCode, mainStore.model, mainStore.storeCountry);
  next();
};
