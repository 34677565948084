import { StoreCountry } from "@/util/constants";

export const formatPrice = ({
  amount,
  locale,
  withDecimals,
  store,
}: {
  amount: number;
  locale: string;
  withDecimals: boolean;
  store: StoreCountry;
}) => {
  let currency;
  if (store === StoreCountry.EU) currency = "EUR";
  if (store === StoreCountry.UK) currency = "GBP";

  // if the amount is e.g 95.50 it is rounded to the nearest (smaller) integer: 95
  if (Number.isInteger(amount - 0.5)) amount = Math.floor(amount);
  const props: any = { style: "currency", currency };
  if (!withDecimals) {
    props.minimumFractionDigits = 0;
    props.maximumFractionDigits = 0;
  }
  return Intl.NumberFormat(locale, props).format(amount);
};
