<script setup lang="ts">
import { RouterView } from "vue-router";
import LayoutFooter from "@/components/layout/LayoutFooter.vue";
import { useMainStore } from "./stores/main";
import LoaderSpinner from "@/components/ui-elements/LoaderSpinner.vue";
import { storeToRefs } from "pinia";
import ErrorWrapper from "@/components/layout/ErrorWrapper.vue";

const mainStore = useMainStore();
const { appLoading, majorError } = storeToRefs(mainStore);
</script>

<template>
  <div v-if="appLoading" class="loader">
    <LoaderSpinner />
  </div>

  <template v-else>
    <RouterView v-if="!majorError.active" />
    <ErrorWrapper v-else>
      <template v-slot:title>{{ majorError.templates.shopifyConfig.title }}</template>
      <template v-slot:text>{{ majorError.templates.shopifyConfig.text }}</template>
    </ErrorWrapper>
    <LayoutFooter />
  </template>
</template>

<style>
@import "@/assets/base.css";

.loader {
  height: 100vh;
  width: 100vw;
  position: relative;
}

/* @define-mixin incercare numeBun {
  color: red;
}

.test-class {
  @mixin incercare;
}

@media (--min-tablet-width) {
  body {
    background-color: red;
  }
} */
</style>
