import type { ContactDetails, CDInputErrors, DeliveryData } from "@/types/FormTypes";
import isEmail from "validator/es/lib/isEmail";

export const validateContactDetails = (data: ContactDetails): { [key in keyof CDInputErrors]: boolean } => {
  return {
    gender: !data.gender,
    phoneNumber: !data.phoneNumber.isValid || !data.phoneNumber.value,
    firstName: !data.firstName,
    lastName: !data.lastName,
    email: !isEmail(data.email),
  };
};

export const validateDeliveryDetails = (data: DeliveryData) => {
  return {
    deliveryAddress: {
      streetCity: !data.deliveryAddress.streetCity,
      houseNo: !data.deliveryAddress.houseNo,
      postalCode: !data.deliveryAddress.postalCode,
    },
    invoiceAddress: {
      streetCity: !data.invoiceAddress.streetCity,
      houseNo: !data.invoiceAddress.houseNo,
      postalCode: !data.invoiceAddress.postalCode,
    },
  };
};
