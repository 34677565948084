import { defineStore } from "pinia";
import { useMainStore } from "@/stores/main";
import { useContactDetailsStore } from "@/stores/contact-details";
import { useDeliveryDetailsStore } from "@/stores/delivery-details";
import { Gender } from "@/types/FormTypes";
import { useDiscountStore } from "@/stores/discount";
import { useReferralStore } from "@/stores/referral";
import PrimerService, { type SelectedPaymentMethod } from "@/services/Primer";
import type { Order } from "@/types/PaymentTypes";
import { CountryFullName, VahaModelSubmitParam } from "@/util/constants";
import ZohoService from "@/services/Zoho";

export const usePaymentStore = defineStore({
  id: "payment",
  state: () => ({
    order: null as Order | null,
    loadingPayment: false,
    errorSDK: false,
    selectedPaymentMethod: null as SelectedPaymentMethod | null,
  }),
  getters: {},
  actions: {
    async sendOrderIntent() {
      PrimerService.resetData();
      this.onLoadingPayment(false);

      this.buildOrder();
      await PrimerService.postClientSession(this.order as Order);
      return;
    },
    async initializeWidget(container: string) {
      const mainStore = useMainStore();
      PrimerService.setCallbacks(this.onPaymentSuccess, this.onPaymentError, this.onLoadingPayment);

      const [err, _success]: any = await PrimerService.initializeSDK(container, mainStore.locale);
      if (err) return (this.errorSDK = true);

      return;
    },
    buildOrder() {
      const mainStore = useMainStore();
      const contactDetailsStore = useContactDetailsStore();
      const deliveryDetailsStore = useDeliveryDetailsStore();
      const discountStore = useDiscountStore();
      const referralStore = useReferralStore();

      const contact = contactDetailsStore.contactDetails;
      const delivery = deliveryDetailsStore.deliveryDetails;
      const order: Order = {
        profile: {
          city: delivery.invoiceAddress.city,
          countryCode: delivery.invoiceAddress.countryCode,
          houseNumber: delivery.invoiceAddress.houseNo,
          streetAddress: delivery.invoiceAddress.street,
          zip: delivery.invoiceAddress.postalCode,
          phone: contact.phoneNumber.value,
          email: contact.email,
          firstName: contact.firstName,
          lastName: contact.lastName,
          gender: contact.gender === Gender.MALE ? "1" : "2",
          deliveryAddress: {
            city: delivery.deliveryAddress.city,
            countryCode: delivery.deliveryAddress.countryCode,
            houseNumber: delivery.deliveryAddress.houseNo,
            streetAddress: delivery.deliveryAddress.street,
            zip: delivery.deliveryAddress.postalCode,
          },
        },
        funnel: "primer",
        vahaModel: VahaModelSubmitParam[mainStore.model],
        vahaStoreCountry: mainStore.storeCountry,
        shippingZoneId: deliveryDetailsStore.shippingZones.selectedId as number,
      };
      if (discountStore.code) order.discountCode = discountStore.code;
      if (referralStore.code) order.referralCode = referralStore.code;
      this.order = order;
    },
    onPaymentSuccess() {
      this.router.replace({ name: "payment-success", query: { ...this.router.currentRoute.value.query } });
    },
    onPaymentError(paymentMethod?: SelectedPaymentMethod) {
      if (paymentMethod) this.selectedPaymentMethod = paymentMethod;
      this.router.push({ name: "payment-failed", query: { ...this.router.currentRoute.value.query } });
    },
    submitPayment() {
      PrimerService.submitPayment();
    },
    onLoadingPayment(val: boolean) {
      this.loadingPayment = val;
    },
    createZohoLead() {
      const mainStore = useMainStore();
      const contactDetailsStore = useContactDetailsStore();
      const deliveryDetailsStore = useDeliveryDetailsStore();
      const country = CountryFullName[mainStore.country];
      const cd: any = { ...contactDetailsStore.contactDetails };
      const dd: any = { ...deliveryDetailsStore.deliveryDetails.deliveryAddress };
      delete dd.firstSuggestion;
      cd.phoneNumber = cd.phoneNumber.value;
      const body = {
        ...cd,
        ...dd,
        vahaModel: mainStore.model,
        country,
        source: ["abandoned checkouts"],
      };
      ZohoService.createdLead(body);
    },

    saveZohoRejectSource() {
      if (!this.selectedPaymentMethod) return;
      ZohoService.saveRejectSource(this.selectedPaymentMethod);
    },
  },
});
