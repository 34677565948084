<script setup lang="ts">
import { computed } from "vue";
import { useMainStore } from "@/stores/main";
import type { Country } from "@/util/constants";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const CountryAccronims: { [key in Country]: string } = {
  uk: "United Kingdom",
  de: "Deutschland",
  at: "Österreich",
};

const mainStore = useMainStore();
const { country } = storeToRefs(mainStore);

const countryFullName = computed(() => CountryAccronims[country.value]);
/**
 * * We can just use the relative urls normally in the template, I did it like this just for the lolz. I think
 * * this solution seems to just give you the full url from the relative path (current modules URL). The target
 * * browser though needs to support this
 */
const imagePaths = computed(() => {
  return {
    country: new URL(`../../assets/flags/${country.value}.svg`, import.meta.url).href,
    vahaLogo: new URL(`../../assets/vaha_logo_yellow.svg`, import.meta.url).href,
    instagramIcon: new URL(`../../assets/social/instagram.svg`, import.meta.url).href,
    facebookIcon: new URL(`../../assets/social/facebook.svg`, import.meta.url).href,
    linkedinIcon: new URL(`../../assets/social/linkedin.svg`, import.meta.url).href,
    youtubeIcon: new URL(`../../assets/social/youtube.svg`, import.meta.url).href,
  };
});
</script>

<template>
  <footer>
    <hr class="line desktop" />
    <hr class="line mobile one" />
    <hr class="line mobile two" />
    <hr class="line mobile three" />

    <div class="footer">
      <div class="vaha-logo">
        <a href="https://uk.vaha.com/" target="_blank" rel="noopener">
          <img src="../../assets/vaha_logo_yellow.svg" alt="vaha-logo" />
        </a>
      </div>

      <div class="group-1">
        <div class="text one">
          <a href="https://uk.vaha.com/product" target="_blank" rel="noopener">{{ t("FOOTER.PRODUCT") }}</a>
        </div>
        <div class="text two">
          <a href="https://vaha-uk.kustomer.help/lang/en_us/" target="_blank" rel="noopener">{{ t("FOOTER.FAQ") }}</a>
        </div>
        <div class="text three">
          <a href="https://vaha.jobs.personio.de/?language=en" target="_blank" rel="noopener">{{ t("FOOTER.JOBS") }}</a>
        </div>
        <div class="text four">
          <a href="https://uk.vaha.com/trainers-and-courses" target="_blank" rel="noopener">{{
            t("FOOTER.TRAINERS&CLASSES")
          }}</a>
        </div>
        <div class="text five">
          <a href="https://vaha-uk.kustomer.help/en_us/contact/contact-uk-SybnAsnN_" target="_blank" rel="noopener">{{
            t("FOOTER.CONTACT")
          }}</a>
        </div>
        <div class="text six">
          <a
            href="https://lp.vaha.com/press-uk/?_ga=2.247404996.1594105072.1624870266-1380034781.1620205168"
            target="_blank"
            rel="noopener"
            >{{ t("FOOTER.PRESS") }}</a
          >
        </div>
      </div>

      <div class="social-icons">
        <a href="https://www.instagram.com/vahaflow_uk/" target="_blank" rel="noopener">
          <img :src="imagePaths.instagramIcon" alt="instagram" />
        </a>
        <a href="https://www.facebook.com/VAHAFLOW/" target="_blank" rel="noopener">
          <img :src="imagePaths.facebookIcon" alt="facebook" />
        </a>
        <a href="https://www.linkedin.com/company/vaha/" target="_blank" rel="noopener">
          <img :src="imagePaths.linkedinIcon" alt="linkedin" />
        </a>
        <a href="https://www.youtube.com/c/VAHAflow/" target="_blank" rel="noopener">
          <img :src="imagePaths.youtubeIcon" alt="youtube" />
        </a>
      </div>

      <div class="country-selector">
        <div class="select-text">{{ t("FOOTER.SELECT_COUNTRY") }}:</div>
        <div class="flag">
          <img :src="imagePaths.country" :alt="`${country}`" />
        </div>
        <div class="country-text">{{ countryFullName }}</div>
      </div>

      <div class="group-2">
        <div class="text one">{{ t("FOOTER.SUPPORT_HOTLINE") }}</div>
        <div class="text two">{{ t("FOOTER.SUPPORT_HOTLINE_VALUE") }}</div>
        <div class="text three">{{ t("FOOTER.EMAIL") }}</div>
        <div class="text four">
          <a href="mailto:support@vaha.uk" target="_blank" rel="noopener">{{ t("FOOTER.EMAIL_VALUE") }}</a>
        </div>
      </div>

      <div class="group-3">
        <div class="text one">
          <a href="https://uk.vaha.com/terms-and-conditions" target="_blank" rel="noopener">{{
            t("FOOTER.TERMS_OF_USE")
          }}</a>
        </div>
        <div class="text two">
          <a href="https://uk.vaha.com/general-purchase-conditions" target="_blank" rel="noopener">{{
            t("FOOTER.TERMS_OF_PURCHASE")
          }}</a>
        </div>
        <div class="text three">
          <a href="https://uk.vaha.com/imprint" target="_blank" rel="noopener">{{ t("FOOTER.IMPRINT") }}</a>
        </div>
        <div class="text four">
          <a href="https://uk.vaha.com/data-protection" target="_blank" rel="noopener">{{
            t("FOOTER.PRIVACY_POLICY")
          }}</a>
        </div>
      </div>

      <div class="legal" ref="ref-legal">
        <div class="legal-text one">{{ t("FOOTER.LEGAL.ONE") + " " }}</div>
        <div class="legal-text two">{{ t("FOOTER.LEGAL.TWO") + " " }}</div>
        <div class="legal-text three">{{ t("FOOTER.LEGAL.THREE") }}</div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
@import "@/assets/base.css";

.footer {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 80px;
  background-color: var(--color-royal-blue);
  color: var(--color-classy-yellow);
  display: grid;
  align-items: center;
  justify-items: left;
}

a,
a:not(.md-button):hover {
  text-decoration: none;
  color: inherit;
}

.vaha-logo {
  grid-area: vaha;
}
.vaha-logo img {
  width: 90px;
  height: 25px;
}

.group-1 {
  font-size: 18px;
  grid-area: groupOne;
  height: 100%;
  width: 100%;
  display: grid;
}
.group-1 .text {
  text-align: left;
  cursor: pointer;
}

.social-icons {
  grid-area: socialIcons;
  width: 60%;
  height: 100%;
  display: grid;
  grid-template-columns: 25fr 25fr 25fr 25fr;
  align-items: center;
  justify-items: left;
}
.social-icons a {
  display: flex;
}
.social-icons img {
  width: 25px;
  height: 25px;
}

.group-2 {
  grid-area: groupTwo;
  display: grid;
  height: 100%;
  width: 100%;
}
.group-2 .one {
  text-align: left;
  grid-area: groupTwoOne;
}
.group-2 .two {
  grid-area: groupTwoTwo;
}
.group-2 .three {
  grid-area: groupTwoThree;
}
.group-2 .four {
  grid-area: groupTwoFour;
  text-decoration: underline;
  cursor: pointer;
}

.group-3 {
  grid-area: groupThree;
  display: grid;
  height: 100%;
  width: 100%;
}
.group-3 .text {
  cursor: pointer;
}
.group-3 .text.one,
.text.two {
  text-align: left;
}

.legal {
  grid-area: legal;
  color: var(--color-stone-grey);
  letter-spacing: 0.02em;
  line-height: 150%;
  font-size: 14px;
  white-space: break-spaces;
  text-align: left;
}
.legal .legal-text {
  display: inline;
}
.legal .two {
  color: var(--color-classy-yellow);
}

.country-selector {
  grid-area: countrySelector;
  display: grid;
  height: 100%;
  width: 100%;
}
.country-selector .flag {
  display: flex;
}
.country-selector .flag img {
  width: 23px;
  height: 23px;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #feba50;
  border-color: #feba50;
  margin: 0;
  position: relative;
  z-index: 1;
}

@media (--min-tablet-width) {
  .footer {
    grid-template-columns: 9fr 15fr 30fr 6fr 8fr 8fr 15fr 9fr;
    grid-template-rows: minmax(100px, auto);
    grid-template-areas:
      " . vaha groupOne groupOne . socialIcons socialIcons ."
      " . countrySelector groupTwo . . . groupThree ."
      " . legal legal legal legal legal legal . ";
    row-gap: 60px;
  }

  .line.desktop {
    top: 11rem;
  }

  .vaha-logo {
    align-self: start;
    margin-top: 15px;
  }

  .group-1 {
    grid-template-columns: 45% 28% 27%;
    align-items: center;
    justify-items: left;
  }

  .country-selector {
    grid-template-columns: 20% 80%;
    grid-template-rows: 20px 30px;
    grid-template-areas:
      " selectText selectText "
      " countryFlag countryText ";
    align-items: center;
    justify-items: left;
  }
  .country-selector .select-text {
    grid-area: selectText;
  }
  .country-selector .flag {
    grid-area: countryFlag;
  }
  .country-selector .country-text {
    grid-area: countryText;
  }

  .group-2 {
    grid-template-columns: 30% 30% 40%;
    grid-template-rows: 30px 30px;
    grid-template-areas:
      " groupTwoOne groupTwoTwo . "
      " groupTwoThree groupTwoFour . ";
    align-items: center;
    justify-items: left;
  }

  .group-3 {
    grid-template-columns: 100%;
    grid-template-rows: minmax(30px, auto) minmax(30px, auto) minmax(30px, auto) minmax(30px, auto);
    align-items: center;
    justify-items: left;
  }

  .line.mobile {
    display: none;
  }
}

@media (--max-tablet-width) {
  .footer {
    align-items: center;
    justify-items: left;
    grid-template-columns: 7% 53% 33% 7%;
    grid-template-rows: minmax(100px, auto);
    grid-template-areas:
      " . groupOne groupOne . "
      " . groupOne groupOne . "
      " . groupOne groupOne . "
      " . groupOne groupOne . "
      " . groupOne groupOne . "
      " . groupOne groupOne . "
      " . socialIcons socialIcons . "
      " . countrySelector countrySelector . "
      " . groupTwo groupTwo . "
      " . groupTwo groupTwo . "
      " . groupThree groupThree . "
      " . groupThree groupThree . "
      " . groupThree groupThree . "
      " . groupThree groupThree . "
      " . vaha . . "
      " . legal legal . ";
  }

  .line.desktop {
    display: none;
  }

  .line.mobile {
    width: 85%;
    margin: 0 auto;
  }

  .line.mobile.one {
    top: 20rem;
  }

  .line.mobile.two {
    top: 26rem;
  }

  .line.mobile.three {
    top: 31.5rem;
  }

  .group-1 {
    grid-template-columns: 100%;
    grid-template-rows: 40px 40px 40px 40px 40px 40px 20px;
    align-items: center;
    justify-items: left;
    grid-template-areas:
      " product "
      " trainers "
      " faq "
      " contact "
      " jobs "
      " press ";
  }
  .group-1 .one {
    grid-area: product;
  }
  .group-1 .two {
    grid-area: faq;
  }
  .group-1 .three {
    grid-area: jobs;
  }
  .group-1 .four {
    grid-area: trainers;
  }
  .group-1 .five {
    grid-area: contact;
  }
  .group-1 .six {
    grid-area: press;
  }

  .social-icons {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .country-selector {
    grid-template-columns: 100px 40px 20fr auto;
    grid-template-rows: 80px 40px;
    grid-template-areas: " selectText countryFlag countryText . ";
    align-items: center;
    justify-items: left;
  }
  .country-selector .select-text {
    grid-area: selectText;
  }
  .country-selector .flag {
    justify-self: start;
    grid-area: countryFlag;
  }
  .country-selector .country-text {
    justify-self: start;
    grid-area: countryText;
  }

  .group-2 {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1.5rem 1.5rem 40px;
    grid-template-areas:
      " groupTwoOne groupTwoTwo "
      " groupTwoThree groupTwoFour ";
    align-items: center;
  }
  .group-2 .one,
  .three {
    justify-self: start;
  }
  .group-2 .two,
  .four {
    justify-self: end;
  }

  .group-3 {
    grid-template-columns: 100%;
    grid-template-rows: 30px 30px 30px 30px;
    align-items: center;
    justify-items: left;
  }

  .vaha-logo {
    align-self: start;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
</style>
