import { defineStore } from "pinia";
import { useMainStore } from "@/stores/main";
import { useDiscountStore } from "@/stores/discount";
import { StoreCountry, VahaModel } from "@/util/constants";
import { formatPrice } from "@/util/formats";
import type { ShippingType, ShippingZone } from "@/types/DeliveryTypes";
import { useDeliveryDetailsStore } from "@/stores/delivery-details";

export const useCartStore = defineStore({
  id: "cart",
  state: () => ({}),
  getters: {
    model(): VahaModel {
      const mainStore = useMainStore();
      return mainStore.model;
    },
    price(): number {
      const discountStore = useDiscountStore();
      return discountStore.price + (this.delivery?.price || 0);
    },
    priceFormatted(): string {
      const mainStore = useMainStore();
      return formatPrice({
        amount: this.price,
        locale: "en",
        withDecimals: true,
        store: mainStore.storeCountry,
      });
    },
    totalPrice(): number {
      const discountStore = useDiscountStore();
      return discountStore.fullPrice;
    },
    totalPriceFormatted(): string {
      const mainStore = useMainStore();
      return formatPrice({
        amount: this.totalPrice,
        locale: "en",
        withDecimals: false,
        store: mainStore.storeCountry,
      });
    },
    symbol(): "€" | "£" {
      const mainStore = useMainStore();
      return mainStore.storeCountry === StoreCountry.EU ? "€" : "£";
    },
    discountCode(): string {
      const discountStore = useDiscountStore();
      return discountStore.code;
    },
    discountValueInAmount(): number {
      const discountStore = useDiscountStore();
      return discountStore.valueInAmount;
    },
    discountValueFormmated(): string {
      const mainStore = useMainStore();
      return formatPrice({
        amount: this.discountValueInAmount,
        locale: "en",
        withDecimals: true,
        store: mainStore.storeCountry,
      });
    },
    delivery(): { price: number; type: ShippingType } | null {
      const deliveryStore = useDeliveryDetailsStore();
      if (!deliveryStore.shippingZones.options.length) return null;

      const selectedZone = deliveryStore.shippingZones.options.find(
        (sz: ShippingZone) => sz.id === deliveryStore.shippingZones.selectedId
      );
      if (!selectedZone) return null;
      return {
        price: selectedZone.cost,
        type: selectedZone?.shippingType,
      };
    },
  },
  actions: {
    updateModel(model: VahaModel) {
      const mainStore = useMainStore();
      const discountStore = useDiscountStore();
      mainStore.updateModel(model);
      discountStore.$reset();
    },
  },
});
