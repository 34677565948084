<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, onMounted } from "vue";
import { usePaymentStore } from "@/stores/payment";
import { storeToRefs } from "pinia";
import IconSpinner from "@/components/icons/IconSpinner.vue";

const timeout = 4000;
const { t } = useI18n();
const paymentStore = usePaymentStore();
const { errorSDK } = storeToRefs(paymentStore);
const checkoutContainer = ref(null);
const widgetLoading = ref(true);

// ----- Hooks -----
onMounted(async () => {
  paymentStore.createZohoLead();

  await paymentStore.sendOrderIntent();
  paymentStore.initializeWidget("#checkout-container");
  waitForWidgetToLoadCompletely();
});
// ----- Hooks -----

// ----- Methods -----
const waitForWidgetToLoadCompletely = () => {
  return new Promise((resolve) => {
    if (!checkoutContainer.value) return;

    let timeoutFunc: any = null;
    const buttonsContSelector = "#primer-checkout-other-payment-methods";
    const config = { attributes: true, childList: true, subtree: true };
    const callback = (mutationsList: any, obs: any) => {
      let buttonsCont: HTMLElement | null = null;
      for (const _mutation of mutationsList) {
        if (buttonsCont || timeoutFunc) break;
        buttonsCont = (checkoutContainer.value as any).querySelector(buttonsContSelector);

        timeoutFunc = setTimeout(() => {
          widgetLoading.value = false;
          obs.disconnect();
          resolve(true);
        }, timeout);
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(checkoutContainer.value, config);
  });
};
// ----- Methods -----
</script>

<template>
  <div class="payment-options">
    <div v-if="!errorSDK" class="title">{{ t("PAYMENT_DETAILS.TITLE") }}</div>
    <div v-if="errorSDK" class="error-sdk">
      <div class="emoji">:-(</div>
      <span class="text"> {{ t("PAYMENT_DETAILS.ERROR_SDK.TEXT") }} </span>
    </div>
    <div v-if="widgetLoading && !errorSDK" class="loading-cont">
      <div class="loading-icon">
        <IconSpinner />
      </div>
      <p>{{ t("PAYMENT_DETAILS.LOADING_TEXT") }}</p>
    </div>

    <div :style="{ opacity: widgetLoading ? '0' : '1' }" id="checkout-container" ref="checkoutContainer"></div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.title {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2em;
}
.payment-options {
  width: 100%;
  height: 100%;
}
.loader {
  height: 100%;
  width: 100%;
  position: relative;
}
.error-sdk {
  min-height: 10rem;
  background-color: var(--color-error-light-red);
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
}
.error-sdk .emoji {
  font-size: 8rem;
}
.error-sdk .text {
  font-weight: bold;
}
.loading-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 1em;
}

#checkout-container {
  background: var(--color-lighter-grey);
  padding: 2em;
  border-radius: 0.5em;
  box-shadow: 0px 0px 10px var(--color-stone-grey);
}
</style>
