import { defineStore } from "pinia";
import { Gender } from "@/types/FormTypes";
import type { ContactDetails } from "@/types/FormTypes";

export const useContactDetailsStore = defineStore({
  id: "contact-details",
  state: (): ContactDetails => ({
    gender: Gender.FEMALE,
    firstName: "",
    lastName: "",
    phoneNumber: {
      value: "",
      countryCode: "",
      isValid: false,
    },
    email: "",
  }),
  getters: {
    contactDetails(): ContactDetails {
      return {
        gender: this.gender,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: { ...this.phoneNumber },
        email: this.email,
      };
    },
    isCompleted(): boolean {
      return (
        !!this.gender &&
        !!this.firstName &&
        !!this.lastName &&
        !!this.email &&
        !!this.phoneNumber.value &&
        !!this.phoneNumber.countryCode &&
        this.phoneNumber.isValid
      );
    },
  },
  actions: {
    updateContactDetails(data: ContactDetails) {
      const { gender, firstName, lastName, phoneNumber, email } = data;
      this.gender = gender;
      this.firstName = firstName;
      this.lastName = lastName;
      this.phoneNumber = phoneNumber;
      this.email = email;
    },
  },
});
