import { defineStore } from "pinia";
import type { DeliveryData } from "@/types/FormTypes";
import { API } from "@/util/api_paths";
import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import { ShippingType, type ShippingZone } from "@/types/DeliveryTypes";

export const useDeliveryDetailsStore = defineStore({
  id: "delivery-details",
  state: () => ({
    deliveryAddress: {
      streetCity: "",
      houseNo: "",
      postalCode: "",
      city: "",
      street: "",
      countryCode: "",
      additionalInfo: "",
    },
    invoiceAddress: {
      streetCity: "",
      houseNo: "",
      postalCode: "",
      city: "",
      street: "",
      countryCode: "",
    },
    sameAddress: true,
    copyright: {
      selected: false,
      errored: false,
    },
    shippingZones: {
      options: [] as ShippingZone[],
      selectedId: null as null | number,
      shippingAAs: {
        administrativeAreaLevel_1Name: "",
        administrativeAreaLevel_2Name: "",
      },
      noShipping: false,
    },
  }),
  getters: {
    deliveryDetails(): DeliveryData {
      return {
        deliveryAddress: {
          streetCity: this.deliveryAddress.streetCity,
          houseNo: this.deliveryAddress.houseNo,
          postalCode: this.deliveryAddress.postalCode,
          city: this.deliveryAddress.city,
          street: this.deliveryAddress.street,
          countryCode: this.deliveryAddress.countryCode,
          additionalInfo: this.deliveryAddress.additionalInfo,
          firstSuggestion: null,
        },
        invoiceAddress: {
          streetCity: this.invoiceAddress.streetCity,
          houseNo: this.invoiceAddress.houseNo,
          postalCode: this.invoiceAddress.postalCode,
          city: this.invoiceAddress.city,
          street: this.invoiceAddress.street,
          countryCode: this.invoiceAddress.countryCode,
          firstSuggestion: null,
        },
        sameAddress: this.sameAddress,
      };
    },
    isCompleted(): boolean {
      return (
        !!this.deliveryAddress.streetCity &&
        !!this.deliveryAddress.houseNo &&
        !!this.deliveryAddress.postalCode &&
        !!this.invoiceAddress.streetCity &&
        !!this.invoiceAddress.houseNo &&
        !!this.invoiceAddress.postalCode &&
        !!this.shippingZones.selectedId
      );
    },
  },
  actions: {
    updateDeliveryDetails(data: DeliveryData) {
      const { deliveryAddress, invoiceAddress, sameAddress } = data;
      this.deliveryAddress.streetCity = deliveryAddress.streetCity;
      this.deliveryAddress.houseNo = deliveryAddress.houseNo;
      this.deliveryAddress.postalCode = deliveryAddress.postalCode;
      this.deliveryAddress.city = deliveryAddress.city;
      this.deliveryAddress.street = deliveryAddress.street;
      this.deliveryAddress.countryCode = deliveryAddress.countryCode;
      this.deliveryAddress.additionalInfo = deliveryAddress.additionalInfo;

      this.invoiceAddress.streetCity = invoiceAddress.streetCity;
      this.invoiceAddress.houseNo = invoiceAddress.houseNo;
      this.invoiceAddress.postalCode = invoiceAddress.postalCode;
      this.invoiceAddress.city = invoiceAddress.city;
      this.invoiceAddress.street = invoiceAddress.street;
      this.invoiceAddress.countryCode = invoiceAddress.countryCode;

      this.sameAddress = sameAddress;
    },
    clearDeliveryDetails() {
      this.deliveryAddress.streetCity = "";
      this.deliveryAddress.houseNo = "";
      this.deliveryAddress.postalCode = "";
      this.deliveryAddress.city = "";
      this.deliveryAddress.street = "";
      this.deliveryAddress.countryCode = "";
      this.deliveryAddress.additionalInfo = "";

      this.invoiceAddress.streetCity = "";
      this.invoiceAddress.houseNo = "";
      this.invoiceAddress.postalCode = "";
      this.invoiceAddress.city = "";
      this.invoiceAddress.street = "";
      this.invoiceAddress.countryCode = "";

      this.sameAddress = true;
    },
    changeCopyrightTermsSelected(val: boolean) {
      if (val) this.changeCopyrightError(false);
      this.copyright.selected = val;
    },
    changeCopyrightError(val: boolean) {
      this.copyright.errored = val;
    },
    clearShippingZones() {
      this.shippingZones.options = [];
      this.shippingZones.selectedId = null;
      this.shippingZones.shippingAAs.administrativeAreaLevel_1Name = "";
      this.shippingZones.shippingAAs.administrativeAreaLevel_2Name = "";
    },
    async fetchShippingZones(obj: { administrativeAreaLevel_1Name: string; administrativeAreaLevel_2Name: string }) {
      if (!obj.administrativeAreaLevel_1Name && !obj.administrativeAreaLevel_2Name) return this.clearShippingZones();
      if (
        obj.administrativeAreaLevel_1Name === this.shippingZones.shippingAAs.administrativeAreaLevel_1Name &&
        obj.administrativeAreaLevel_2Name === this.shippingZones.shippingAAs.administrativeAreaLevel_2Name
      )
        return;

      const url = API.SHIPPING_ZONES.replace("{AA1}", obj.administrativeAreaLevel_1Name).replace(
        "{AA2}",
        obj.administrativeAreaLevel_2Name
      );
      try {
        const res = await axios.get(url);
        const zones: ShippingZone[] = camelcaseKeys(res.data, { deep: true }).shippingZones;
        const selectedId =
          zones.find((zone: ShippingZone) => zone.shippingType === ShippingType.STANDARD)?.id ||
          zones.find((zone: ShippingZone) => zone.shippingType === ShippingType.PREMIUM)?.id ||
          null;
        this.shippingZones.options = zones;
        this.shippingZones.selectedId = selectedId;
        this.shippingZones.shippingAAs = { ...obj };
        this.changeNoShipping(false);
        return;
      } catch (err: any) {
        console.error("Error fetching shipping zones, ", err);
        if (err.response.status === 404) {
          if (err.response.data.error === "No Shipping Zones Found") this.changeNoShipping(true);
          return this.clearShippingZones();
        }
      }
    },
    changeShippingZoneId(id: number) {
      this.shippingZones.selectedId = id;
    },
    changeNoShipping(val: boolean) {
      this.shippingZones.noShipping = val;
    },
  },
});
