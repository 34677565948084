<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useCartStore } from "@/stores/cart";
import { useMainStore } from "@/stores/main";
import { storeToRefs } from "pinia";
import { VahaModel } from "@/util/constants";
import DropdownInput from "@/components/ui-elements/DropdownInput.vue";
import CollapsibleGroup from "@/components/ui-elements/CollapsibleGroup.vue";
import { formatPrice } from "@/util/formats";
import { useRoute } from "vue-router";
import { ShippingType } from "@/types/DeliveryTypes";

const { t } = useI18n();
const cartStore = useCartStore();
const mainStore = useMainStore();
const route = useRoute();

const membershipValue = { value: "£39" };
const transformationPackage = {
  title: `${t("CART.MEMB_GROUP.TITLE")}:`,
  items: [
    { title: t("CART.MEMB_GROUP.MPTS.TITLE"), desc: t("CART.MEMB_GROUP.MPTS.DESC") },
    { title: t("CART.MEMB_GROUP.VSB.TITLE"), desc: t("CART.MEMB_GROUP.VSB.DESC") },
    { title: t("CART.MEMB_GROUP.GV.TITLE"), desc: t("CART.MEMB_GROUP.GV.DESC") },
  ],
};

const { model, totalPriceFormatted, discountValueFormmated, discountCode, priceFormatted, delivery } =
  storeToRefs(cartStore);

const vahaModel = computed(() => {
  return model.value.replace("_", " ");
});
const vahaModelX = computed(() => {
  return VahaModel.VAHA_X.replace("_", " ");
});
const vahaModelS = computed(() => {
  return VahaModel.VAHA_S.replace("_", " ");
});
const vahaModelDropdownDisabled = computed(() => {
  return route.name === "payment-options";
});
const deliveryFormattedPrice = computed(() => {
  if (!delivery.value) return "";
  if (!delivery.value.price) return t("DELIVERY_DETAILS.SHIPPING.FREE");
  return formatPrice({
    amount: delivery.value.price,
    locale: mainStore.locale,
    withDecimals: true,
    store: mainStore.storeCountry,
  });
});

const changeModel = (newModel: string) => {
  const model = newModel.replace(" ", "_") as VahaModel;
  cartStore.updateModel(model);
};
</script>

<template>
  <div class="cart-details">
    <div class="title font-big bold">{{ t("CART.TITLE") }}</div>

    <div class="group">
      <div class="row model-price font-big flex-out">
        <DropdownInput
          :options="[vahaModelX, vahaModelS]"
          :selectedOption="vahaModel"
          @changeOption="changeModel"
          :disabled="vahaModelDropdownDisabled"
        />
      </div>
      <div class="row flex-out">
        <div class="pr-1">
          <div class="font-big">{{ t("CART.MEMBERSHIP.TITLE") }}</div>
          <div class="memb-details font-small grey">{{ t("CART.MEMBERSHIP.DETAILS", membershipValue) }}</div>
        </div>
        <div class="memb-price">
          <div class="font-big">{{ totalPriceFormatted }} / {{ t("CART.MEMBERSHIP.MONTH_SHORT") }}</div>
          <div class="font-small">{{ t("CART.MEMBERSHIP.TIMEFRAME") }}</div>
        </div>
      </div>
    </div>

    <div class="group">
      <CollapsibleGroup>
        <template v-slot:title>{{ transformationPackage.title }}</template>
        <template v-slot:items>
          <div class="row memb-group" v-for="(item, index) in transformationPackage.items" :key="index">
            <div class="font-big">{{ item.title }}</div>
            <div class="font-small grey">{{ item.desc }}</div>
          </div>
        </template>
      </CollapsibleGroup>
    </div>

    <div class="group" v-if="delivery && delivery.type === ShippingType.STANDARD" data-group="delivery">
      <div class="row flex-out">
        <div>
          <div class="font-big">{{ t("CART.DELIVERY.SD.TITLE") }}</div>
          <div class="font-small grey">{{ t("CART.DELIVERY.SD.DESC") }}</div>
        </div>
        <div>
          <span class="font-medium crossed mr-05">£99</span>
          <span class="font-big red">{{ deliveryFormattedPrice }}</span>
        </div>
      </div>

      <div class="row flex-out">
        <div>
          <div class="font-big">{{ t("CART.DELIVERY.30.TITLE") }}</div>
          <div class="font-small grey">{{ t("CART.DELIVERY.30.DESC") }}</div>
        </div>
        <div class="font-big red">{{ t("DELIVERY_DETAILS.SHIPPING.FREE") }}</div>
      </div>
    </div>

    <div class="group row flex-out" v-if="delivery && delivery.type === ShippingType.PREMIUM">
      <div>
        <div class="font-medium">{{ t("CART.DELIVERY.PD.TITLE") }}</div>
        <div class="font-small">{{ t("CART.DELIVERY.PD.DESC") }}</div>
      </div>

      <div>
        <span class="font-big">{{ deliveryFormattedPrice }}</span>
      </div>
    </div>

    <div v-if="discountCode" class="row group" data-group="discount">
      <div class="flex-out">
        <div class="text-left">
          <div class="font-small">{{ t("CART.DISCOUNT.TITLE") }}</div>
          <div class="font-small bold">{{ discountCode }}</div>
        </div>
        <div class="font-big red">-{{ discountValueFormmated }}</div>
      </div>
      <div>
        <span class="font-very-small grey">*{{ t("CART.DISCOUNT.PRICE_INFO") }}</span>
      </div>
    </div>

    <div class="row total-cont">
      <div class="row flex-out">
        <div class="bold font-big">{{ t("CART.TOTAL.PAY_NOW") }}</div>
        <div class="text-right">
          <div class="bold font-big">{{ priceFormatted }}</div>
        </div>
      </div>
      <div class="row flex-out">
        <div class="bold font-big">{{ t("CART.TOTAL.AND_THEN") }}</div>
        <div class="text-right">
          <div class="bold font-big">{{ totalPriceFormatted }} / {{ t("CART.MEMBERSHIP.MONTH_SHORT") }}</div>
          <div class="font-small bold">{{ t("CART.TOTAL.TIMEFRAME") }} ({{ t("CART.TOTAL.VAT") }})</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
.font-small {
  font-size: 0.8rem;
}
.font-big {
  letter-spacing: 0.05em;
  font-size: 1.1rem;
}
.font-medium {
  font-size: 1rem;
}
.font-very-small {
  font-size: 0.8rem;
}
.flex-out {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crossed {
  text-decoration: line-through;
}
.mr-05 {
  margin-right: 0.5em;
}
.row {
  margin: 0.5em 0 0.5em 0;
}
.pr-1 {
  padding-right: 1em;
}
.grey {
  color: var(--color-medium-dark-grey);
}

.cart-details {
  background-color: var(--color-lighter-grey);
  padding: 1.5em;
  text-align: left;
  box-shadow: 0px 0px 10px var(--color-stone-grey);
}
.group {
  border-bottom: 1px solid var(--color-medium-dark-grey);
  padding: 1.5em 0 1.5em 0;
}
.title {
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--color-medium-dark-grey);
}
.row.model-price {
  margin-bottom: 0.3em;
}
.total-cont {
  align-items: flex-start;
  margin-top: 1em;
}
.text-right {
  text-align: right;
}
.col-prices {
  margin-left: 1em;
  font-size: 1.1em;
}

@media (--max-tablet-width) {
  .memb-details {
    max-width: 100%;
  }
}

@media (--max-phone-width) {
  .memb-price .font-big {
    font-size: 1rem;
  }
}
</style>
