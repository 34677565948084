import axios from "axios";
import { API } from "@/util/api_paths";
import type { SelectedPaymentMethod } from "./Primer";

const ZohoService = {
  leadId: "",
  contactId: "", // * Not doing anything with it though 🤪

  reset() {
    this.leadId = "";
    this.contactId = "";
  },

  async createdLead(payload: any) {
    this.reset();

    try {
      const res: any = await axios.post(`${API.LEADS}`, payload);
      this.leadId = res.data.leadId;
    } catch (err) {
      console.error("Error trying to create new Lead, ", err);
    }
  },

  async convertLead() {
    if (!this.leadId) return;

    try {
      const res: any = await axios.put(`${API.LEADS}/convert/${this.leadId}`, {});
      this.contactId = res.data.contactId;
    } catch (err) {
      console.error("Error trying to convert Lead to Contact, ", err);
    }
  },

  saveRejectSource(source: SelectedPaymentMethod) {
    if (!this.leadId) return;

    try {
      axios.put(`${API.LEADS}/${this.leadId}`, { source });
    } catch (err) {
      console.error("Error trying to update source for the rejected Lead, ", err);
    }
  },
};

export default ZohoService;
