export const SYSTEM_BASE_PATH = `${import.meta.env.VITE_API_HOST}/system/api/v1`;
export const CHECKOUT_BASE_PATH_V2 = `${import.meta.env.VITE_API_HOST}/checkout/api/v2`;
export const CHECKOUT_BASE_PATH_V1 = `${import.meta.env.VITE_API_HOST}/checkout/api/v1`;
export const API_BASE_PATH = `${import.meta.env.VITE_NODE_API_HOST}/api`;

export const API = {
  VOUCHER: `${SYSTEM_BASE_PATH}/shopify/discount_status`,
  LEADS: `${API_BASE_PATH}/leads`,
  // CONTACTS: `${API_BASE_PATH}/contacts`,
  // SKU: `${SYSTEM_BASE_PATH}/shopify/sku`,
  REFERRAL_CODE: `${CHECKOUT_BASE_PATH_V2}/referral_codes/{referralCode}/validate`,
  SHARED_POSTS: "https://magazine.vaha.com/wp-json/shared_posts/v1",
  SHIPPING_ZONES: `${CHECKOUT_BASE_PATH_V1}/shipping_zones?administrative_area_level_1_name={AA1}&administrative_area_level_2_name={AA2}`,
  CLIENT_SESSION: `${CHECKOUT_BASE_PATH_V1}/submit`,
  PAYMENTS: `${CHECKOUT_BASE_PATH_V1}/create_payment`,
  PAYMENTS_RESUME: `${CHECKOUT_BASE_PATH_V1}/resume_payment`,
};
