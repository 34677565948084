<script setup lang="ts">
import { ref } from "vue";

const props = defineProps<{
  options: string[];
  selectedOption: string;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: "changeOption", option: string): void;
}>();

const showDropdown = ref(false);

const selectListItem = (item: string) => {
  showDropdown.value = false;
  emit("changeOption", item);
};

const toggleDropdown = () => {
  if (props.disabled) return;

  showDropdown.value = !showDropdown.value;
};
</script>

<template>
  <div class="dropdown-input">
    <div :class="{ 'selected-option': true, disabled }" @click="toggleDropdown">
      <span>{{ selectedOption }}</span>
      <img
        v-if="!disabled"
        src="../../assets/util/left-arrow.svg"
        :class="['icon-arrow', { show: showDropdown }]"
        alt="arrow"
      />
    </div>

    <transition name="dissapear">
      <div class="list" v-if="showDropdown">
        <div
          :class="['list-item', { active: selectedOption === listItem }]"
          v-for="listItem in options"
          :key="listItem"
          @click="selectListItem(listItem)"
        >
          {{ listItem }}
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.dropdown-input {
  position: relative;
}
.icon-arrow {
  width: 20px;
  height: 20px;
  transition: transform 0.2s;
  transform: rotate(-90deg);
}
.icon-arrow.show {
  transform: rotate(90deg);
}
.selected-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected-option.disabled {
  cursor: default;
}
.selected-option span {
  letter-spacing: 0.05em;
  font-size: 1.1rem;
  margin-right: 0.6em;
}

.list {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  background: white;
  position: absolute;
  z-index: 2;
  width: 99%;
  margin: 0 auto;
  box-shadow: 0px 0px 5px var(--color-light-grey);
}
.list-item {
  color: black;
  height: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed grey;
  cursor: pointer;
  font-size: 1rem;
}
.list-item:hover {
  background: #dddddd;
}
.list-item.active {
  background-color: #dddddd;
}

.dissapear-enter-active,
.dissapear-leave-active {
  transition: opacity 0.3s;
}
.dissapear-enter-from,
.dissapear-leave-to {
  opacity: 0;
}
</style>
