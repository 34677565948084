<script setup lang="ts">
import { reactive } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const faqToggleState = reactive({
  one: false,
  two: false,
  three: false,
});

const toggleFaq = (keyName: "one" | "two" | "three") => {
  faqToggleState[keyName] = !faqToggleState[keyName];
};
</script>

<template>
  <div class="faq-cont">
    <div class="faq-title">
      <span>{{ t("PAYMENT_DETAILS.FAQ.TITLE") }}</span>
    </div>
    <div class="faq-line">
      <div class="line">
        <span>{{ t("PAYMENT_DETAILS.FAQ.QUESTIONS.ONE.TITLE") }}</span>
        <img src="../../assets/util/add.png" alt="add" @click="toggleFaq('one')" />
      </div>
      <transition name="slide" appear>
        <div v-if="faqToggleState.one" class="faq-toggled-text">{{ t("PAYMENT_DETAILS.FAQ.QUESTIONS.ONE.TEXT") }}</div>
      </transition>
    </div>
    <div class="faq-line">
      <div class="line">
        <span>{{ t("PAYMENT_DETAILS.FAQ.QUESTIONS.TWO.TITLE") }}</span>
        <img src="../../assets/util/add.png" alt="add" @click="toggleFaq('two')" />
      </div>
      <transition name="slide" appear>
        <div v-if="faqToggleState.two" class="faq-toggled-text">{{ t("PAYMENT_DETAILS.FAQ.QUESTIONS.TWO.TEXT") }}</div>
      </transition>
    </div>
    <div class="faq-line">
      <div class="line">
        <span>{{ t("PAYMENT_DETAILS.FAQ.QUESTIONS.THREE.TITLE") }}</span>
        <img src="../../assets/util/add.png" alt="add" @click="toggleFaq('three')" />
      </div>
      <transition name="slide" appear>
        <div v-if="faqToggleState.three" class="faq-toggled-text">
          {{ t("PAYMENT_DETAILS.FAQ.QUESTIONS.THREE.TEXT") }}
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.faq-cont {
  max-width: 40rem;
  margin: 3em auto 0 auto;
}
.faq-title {
  font-size: 2em;
  font-weight: bold;
  padding: 1em 0 1em 0;
  line-height: 1.3em;
}
.faq-line {
  padding: 1em 0 1em 0;
  border-top: 1px solid black;
}
.faq-line .line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1em;
}
.faq-line span {
  font-size: 1.2em;
}
.faq-line img {
  width: 40px;
  cursor: pointer;
}
.faq-toggled-text {
  margin-top: 1em;
  font-size: 0.9em;
  text-align: left;
}
</style>
