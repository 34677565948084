<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, reactive, watchEffect, computed, onBeforeMount, watch } from "vue";
import TextInput from "./ui-elements/TextInput.vue";
import CheckboxInput from "@/components/ui-elements/CheckboxInput.vue";
import { useGooglePredictions } from "@/composables/useGooglePredictions";
import type { DDInputErrors, DeliveryData } from "@/types/FormTypes";
import { validateDeliveryDetails } from "@/util/form-validations";
import { onBeforeRouteLeave } from "vue-router";
import { useDeliveryDetailsStore } from "@/stores/delivery-details";
import { storeToRefs } from "pinia";
import ShippingCard from "@/components/ui-elements/ShippingCard.vue";
import { formatPrice } from "@/util/formats";
import { useMainStore } from "@/stores/main";
import { ShippingType, type ShippingZone } from "@/types/DeliveryTypes";

const deliveryDetailsStore = useDeliveryDetailsStore();
const mainStore = useMainStore();
const { deliveryDetails: ddStore, isCompleted } = deliveryDetailsStore;
const { shippingZones } = storeToRefs(deliveryDetailsStore);

// ----- Data -----
const { t } = useI18n();
const deliveryDetailsForm: DeliveryData = reactive({
  deliveryAddress: {
    streetCity: "",
    houseNo: "",
    postalCode: "",
    city: "",
    street: "",
    countryCode: "",
    additionalInfo: "",
    firstSuggestion: null,
  },
  invoiceAddress: {
    streetCity: "",
    houseNo: "",
    postalCode: "",
    city: "",
    street: "",
    countryCode: "",
    firstSuggestion: null,
  },
  sameAddress: true,
});

const houseNoCompDel = ref(null);
const houseNoCompInv = ref(null);
const {
  address: deliveryGoogleAddress,
  predictions: deliveryGooglePredictions,
  selectPrediction: selectDeliveryGooglePrediction,
  changeShouldPredict: shouldPredictDelivery,
  autofill: autofillDelivery,
  shippingAAs: googleShippingAAs,
} = useGooglePredictions(houseNoCompDel, deliveryDetailsForm.deliveryAddress);
const {
  address: invoiceGoogleAddress,
  predictions: invoiceGooglePredictions,
  selectPrediction: selectInvoiceGooglePrediction,
  changeShouldPredict: shouldPredictInvocie,
  autofill: autofillInvoice,
} = useGooglePredictions(houseNoCompInv, deliveryDetailsForm.invoiceAddress);

const customerTriedToMoveOnAtLeastOnce = ref(false);
// ----- Data -----

// ----- Hooks -----
onBeforeMount(() => {
  if (!isCompleted) return;

  shouldPredictDelivery(false);
  shouldPredictInvocie(false);

  deliveryDetailsForm.deliveryAddress.streetCity = ddStore.deliveryAddress.streetCity;
  deliveryDetailsForm.deliveryAddress.houseNo = ddStore.deliveryAddress.houseNo;
  deliveryDetailsForm.deliveryAddress.postalCode = ddStore.deliveryAddress.postalCode;
  deliveryDetailsForm.deliveryAddress.city = ddStore.deliveryAddress.city;
  deliveryDetailsForm.deliveryAddress.street = ddStore.deliveryAddress.street;
  deliveryDetailsForm.deliveryAddress.countryCode = ddStore.deliveryAddress.countryCode;
  deliveryDetailsForm.deliveryAddress.additionalInfo = ddStore.deliveryAddress.additionalInfo;
  deliveryDetailsForm.invoiceAddress.streetCity = ddStore.invoiceAddress.streetCity;
  deliveryDetailsForm.invoiceAddress.houseNo = ddStore.invoiceAddress.houseNo;
  deliveryDetailsForm.invoiceAddress.city = ddStore.invoiceAddress.city;
  deliveryDetailsForm.invoiceAddress.street = ddStore.invoiceAddress.street;
  deliveryDetailsForm.invoiceAddress.countryCode = ddStore.invoiceAddress.countryCode;
  deliveryDetailsForm.invoiceAddress.postalCode = ddStore.invoiceAddress.postalCode;
  deliveryDetailsForm.sameAddress = ddStore.sameAddress;

  autofillDelivery({
    streetCity: ddStore.deliveryAddress.streetCity,
    houseNo: ddStore.deliveryAddress.houseNo,
    postalCode: ddStore.deliveryAddress.postalCode,
    city: ddStore.deliveryAddress.city,
    street: ddStore.deliveryAddress.street,
    countryCode: ddStore.deliveryAddress.countryCode,
  });
  autofillInvoice({
    streetCity: ddStore.invoiceAddress.streetCity,
    houseNo: ddStore.invoiceAddress.houseNo,
    postalCode: ddStore.invoiceAddress.postalCode,
    city: ddStore.invoiceAddress.city,
    street: ddStore.invoiceAddress.street,
    countryCode: ddStore.invoiceAddress.countryCode,
  });
});
// ----- Hooks -----

// ----- Methods -----
const updateInvoiceAddressWithDelivery = () => {
  deliveryDetailsForm.invoiceAddress.streetCity = deliveryDetailsForm.deliveryAddress.streetCity;
  deliveryDetailsForm.invoiceAddress.houseNo = deliveryDetailsForm.deliveryAddress.houseNo;
  deliveryDetailsForm.invoiceAddress.postalCode = deliveryDetailsForm.deliveryAddress.postalCode;
  deliveryDetailsForm.invoiceAddress.city = deliveryDetailsForm.deliveryAddress.city;
  deliveryDetailsForm.invoiceAddress.street = deliveryDetailsForm.deliveryAddress.street;
  deliveryDetailsForm.invoiceAddress.countryCode = deliveryDetailsForm.deliveryAddress.countryCode;
};

const isInputErrored = (val: boolean) => {
  return val && customerTriedToMoveOnAtLeastOnce.value;
};

const allFieldsValid = () => {
  const valuesArr = Object.values(validationErrors.value)
    .map((nestObj) => Object.values(nestObj))
    .flat();
  return valuesArr.every((val: boolean) => !val);
};

const allFieldsEmpty = () => {
  const valuesArr = Object.values(validationErrors.value)
    .map((nestObj) => Object.values(nestObj))
    .flat();
  return valuesArr.every((val: boolean) => !!val);
};

const resetInvoiceAddress = () => {
  deliveryDetailsForm.invoiceAddress.streetCity = "";
  deliveryDetailsForm.invoiceAddress.houseNo = "";
  deliveryDetailsForm.invoiceAddress.postalCode = "";
  deliveryDetailsForm.invoiceAddress.city = "";
  deliveryDetailsForm.invoiceAddress.street = "";
  deliveryDetailsForm.invoiceAddress.countryCode = "";
  deliveryDetailsForm.invoiceAddress.firstSuggestion = null;
};

const resetDeliveryAddress = () => {
  deliveryDetailsForm.deliveryAddress.streetCity = "";
  deliveryDetailsForm.deliveryAddress.houseNo = "";
  deliveryDetailsForm.deliveryAddress.postalCode = "";
  deliveryDetailsForm.deliveryAddress.city = "";
  deliveryDetailsForm.deliveryAddress.street = "";
  deliveryDetailsForm.deliveryAddress.countryCode = "";
  deliveryDetailsForm.deliveryAddress.additionalInfo = "";
  deliveryDetailsForm.deliveryAddress.firstSuggestion = null;
};

const changeSameAddress = (val: boolean) => {
  deliveryDetailsForm.sameAddress = val;

  if (!val) resetInvoiceAddress();
};

const changeShippingZone = (id: number) => {
  deliveryDetailsStore.changeShippingZoneId(id);
};

const formatShippingPrice = (amount: number) => {
  if (!amount) return t("DELIVERY_DETAILS.SHIPPING.FREE");
  return formatPrice({ amount, locale: mainStore.locale, withDecimals: false, store: mainStore.storeCountry });
};
// ----- Methods -----

// ----- Watchers -----
watchEffect(() => {
  if (!deliveryGoogleAddress.streetCity) {
    resetDeliveryAddress();
    resetInvoiceAddress();
    return;
  }

  deliveryDetailsForm.deliveryAddress.streetCity = deliveryGoogleAddress.streetCity;
  deliveryDetailsForm.deliveryAddress.houseNo = deliveryGoogleAddress.houseNo;
  deliveryDetailsForm.deliveryAddress.postalCode = deliveryGoogleAddress.postalCode;
  deliveryDetailsForm.deliveryAddress.firstSuggestion = deliveryGoogleAddress.firstSuggestion;
  deliveryDetailsForm.deliveryAddress.city = deliveryGoogleAddress.city;
  deliveryDetailsForm.deliveryAddress.street = deliveryGoogleAddress.street;
  deliveryDetailsForm.deliveryAddress.countryCode = deliveryGoogleAddress.countryCode;

  deliveryDetailsForm.invoiceAddress.streetCity = invoiceGoogleAddress.streetCity;
  deliveryDetailsForm.invoiceAddress.houseNo = invoiceGoogleAddress.houseNo;
  deliveryDetailsForm.invoiceAddress.postalCode = invoiceGoogleAddress.postalCode;
  deliveryDetailsForm.invoiceAddress.firstSuggestion = invoiceGoogleAddress.firstSuggestion;
  deliveryDetailsForm.invoiceAddress.city = invoiceGoogleAddress.city;
  deliveryDetailsForm.invoiceAddress.street = invoiceGoogleAddress.street;
  deliveryDetailsForm.invoiceAddress.countryCode = invoiceGoogleAddress.countryCode;
});

watch(
  () => deliveryDetailsForm.deliveryAddress.streetCity,
  (val: string) => {
    if (!val) deliveryDetailsStore.clearShippingZones();
  }
);

watch(googleShippingAAs, () => {
  deliveryDetailsStore.fetchShippingZones(googleShippingAAs);
});
// ----- Watchers -----

// ----- Computed -----
const validationErrors = computed<DDInputErrors>(() => {
  return validateDeliveryDetails(deliveryDetailsForm);
});

const filteredShippingZones = computed(() => {
  return shippingZones.value.options.filter((sz: ShippingZone) => sz.shippingType !== ShippingType.PREMIUM);
});
// ----- Computed -----

// ----- Guards -----
onBeforeRouteLeave((to, _from, next) => {
  const nextFn = (val: boolean, nextCb: (nextVal: boolean) => void) => {
    if (val) deliveryDetailsStore.changeNoShipping(false);
    nextCb(val);
  };

  if (deliveryDetailsForm.sameAddress) updateInvoiceAddressWithDelivery();
  const areAllFieldsValid = allFieldsValid();
  const areAllFieldsEmpty = allFieldsEmpty();
  if ((areAllFieldsValid || areAllFieldsEmpty) && !shippingZones.value.noShipping) {
    deliveryDetailsStore.updateDeliveryDetails(deliveryDetailsForm);
  }

  if (to.name === "contact-details") return nextFn(true, next);

  customerTriedToMoveOnAtLeastOnce.value = true;

  const termsSelected = deliveryDetailsStore.copyright.selected;
  if (!termsSelected) {
    deliveryDetailsStore.changeCopyrightError(true);
    return nextFn(false, next);
  }

  nextFn(deliveryDetailsStore.isCompleted, next);
});
// ----- Guards -----
</script>

<template>
  <div class="delivery-details">
    <span class="page-title">{{ t("DELIVERY_DETAILS.TITLE") }}</span>
    <div class="delivery-form">
      <div class="row group-one">
        <div class="street-city-cont">
          <TextInput
            v-model="deliveryDetailsForm.deliveryAddress.streetCity"
            :labelText="t('DELIVERY_DETAILS.LABELS.STREET_CITY')"
            :inputName="'street-city'"
            :suggestion="deliveryDetailsForm.deliveryAddress.firstSuggestion?.description"
            :dontAutocomplete="true"
            :errored="isInputErrored(validationErrors.deliveryAddress.streetCity)"
          />
          <div v-if="deliveryGooglePredictions.autocompleteList.length" class="google-prediction-list">
            <div
              :class="['google-list-item', { suggested: index === 0 ? true : false }]"
              v-for="(pred, index) in deliveryGooglePredictions.autocompleteList"
              :key="pred.place_id"
              @click="selectDeliveryGooglePrediction(pred)"
            >
              {{ pred.description }}
            </div>
          </div>
        </div>

        <TextInput
          v-model="deliveryDetailsForm.deliveryAddress.houseNo"
          :labelText="t('DELIVERY_DETAILS.LABELS.HOUSE_NO')"
          :inputName="'house-no'"
          ref="houseNoCompDel"
          :disabled="!deliveryDetailsForm.deliveryAddress.streetCity"
          :errored="isInputErrored(validationErrors.deliveryAddress.houseNo)"
        />
      </div>
      <div class="row group-two">
        <TextInput
          v-model="deliveryDetailsForm.deliveryAddress.postalCode"
          :labelText="t('DELIVERY_DETAILS.LABELS.POSTAL_CODE')"
          :inputName="'postalCode'"
          :disabled="true"
          :errored="isInputErrored(validationErrors.deliveryAddress.postalCode)"
        />
        <TextInput
          v-model="deliveryDetailsForm.deliveryAddress.additionalInfo"
          :labelText="t('DELIVERY_DETAILS.LABELS.ADDITIONAL')"
          :inputName="'additionalInfo'"
        />
      </div>
    </div>
    <div class="same-address">
      <CheckboxInput :value="deliveryDetailsForm.sameAddress" @emitClick="changeSameAddress" />
      <span>{{ t("DELIVERY_DETAILS.SAME_ADDRESS") }}</span>
    </div>
    <transition name="slide" appear>
      <div v-show="!deliveryDetailsForm.sameAddress" class="invoice-address">
        <span class="billing-title">{{ t("DELIVERY_DETAILS.BILLING_TITLE") }}</span>
        <div class="row group-one">
          <div class="street-city-cont">
            <TextInput
              v-model="deliveryDetailsForm.invoiceAddress.streetCity"
              :labelText="t('DELIVERY_DETAILS.LABELS.STREET_CITY')"
              :inputName="'street-city'"
              :suggestion="deliveryDetailsForm.invoiceAddress.firstSuggestion?.description"
              :dontAutocomplete="true"
              :errored="isInputErrored(validationErrors.invoiceAddress.streetCity)"
            />
            <div v-if="invoiceGooglePredictions.autocompleteList.length" class="google-prediction-list">
              <div
                :class="['google-list-item', { suggested: index === 0 ? true : false }]"
                v-for="(pred, index) in invoiceGooglePredictions.autocompleteList"
                :key="pred.place_id"
                @click="selectInvoiceGooglePrediction(pred)"
              >
                {{ pred.description }}
              </div>
            </div>
          </div>
          <TextInput
            v-model="deliveryDetailsForm.invoiceAddress.houseNo"
            :labelText="t('DELIVERY_DETAILS.LABELS.HOUSE_NO')"
            :inputName="'house-no'"
            ref="houseNoCompInv"
            :disabled="!invoiceGoogleAddress.streetCity"
            :errored="isInputErrored(validationErrors.invoiceAddress.houseNo)"
          />
        </div>
        <div class="row group-two">
          <TextInput
            v-model="deliveryDetailsForm.invoiceAddress.postalCode"
            :labelText="t('DELIVERY_DETAILS.LABELS.POSTAL_CODE')"
            :inputName="'postalCode'"
            :disabled="true"
            :errored="isInputErrored(validationErrors.invoiceAddress.postalCode)"
          />
        </div>
      </div>
    </transition>

    <div class="shipping-cards-cont" v-if="shippingZones.selectedId">
      <ShippingCard
        v-for="sz in filteredShippingZones"
        :key="sz.id"
        :value="sz.id"
        @update="changeShippingZone"
        :activeValue="shippingZones.selectedId"
      >
        <template v-slot:title>{{ t(`DELIVERY_DETAILS.SHIPPING.${sz.shippingType.toUpperCase()}.TITLE`) }}</template>
        <template v-slot:text>{{ t(`DELIVERY_DETAILS.SHIPPING.${sz.shippingType.toUpperCase()}.TEXT`) }}</template>
        <template v-slot:price>
          <span :class="{ red: !sz.cost }">{{ formatShippingPrice(sz.cost) }}</span>
        </template>
      </ShippingCard>
    </div>
    <div class="no-shipping" v-if="shippingZones.noShipping">
      <div class="title">{{ t("DELIVERY_DETAILS.SHIPPING.NO.TITLE") }}</div>
      <div class="text-cont">
        <span class="text">{{ t("DELIVERY_DETAILS.SHIPPING.NO.TEXT") }}</span>
        <div class="support">
          <a href="mailto:support@vaha.uk" target="_blank" rel="noopener">{{ t("FOOTER.EMAIL_VALUE") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.page-title {
  font-size: 1.8rem;
  font-weight: 700;
  display: block;
  text-align: left;
  margin-bottom: 1em;
}

.row {
  display: grid;
  -moz-column-gap: 2em;
  column-gap: 2em;
  margin-bottom: 2em;
}
.group-one {
  grid-template-columns: 4fr 1fr;
}
.group-two {
  grid-template-columns: 1fr 2fr;
}
.group-three {
  grid-template-columns: 1fr 1fr 2fr;
}
.google-prediction-list {
  width: 100%;
  z-index: 1;
  border: 1px solid #c3c3c3;
  border-radius: 0.5rem;
  text-align: left;
  background-color: white;
  overflow: hidden;
  position: absolute;
}
.google-list-item {
  padding: 1em;
  cursor: pointer;
  border-bottom: 1px solid #c3c3c3;
}
.google-list-item.suggested {
  background-color: var(--color-light-grey);
}
.google-list-item:hover {
  background-color: var(--color-lighter-grey);
}
.google-list-item:last-child {
  border-bottom: none;
}

.street-city-cont {
  position: relative;
}

.same-address {
  margin-top: 3em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.same-address .checkbox {
  display: flex;
}

.invoice-address {
  margin-top: 3em;
}
.billing-title {
  font-size: 1.4rem;
  font-weight: 700;
  display: block;
  text-align: left;
  margin-bottom: 1em;
}

@media (--max-phone-width) {
  .row {
    margin-bottom: unset;
  }
  .row > .text-input {
    margin-bottom: 2em;
  }
  .group-one {
    grid-template-columns: 1fr;
  }
  .group-two {
    grid-template-columns: 1fr;
  }

  .street-city-cont {
    margin-bottom: 1em;
  }
}

.shipping-cards-cont {
  margin-top: 2em;
}
.no-shipping {
  margin-top: 2em;
  background-color: var(--color-error-light-red);
  border: 1px solid var(--color-error-red);
  border-radius: 5px;
  width: 100%;
  padding: 1em;
}
.no-shipping .title {
  font-size: 1.1em;
  font-weight: bold;
  text-align: left;
}
.no-shipping .text {
  text-align: left;
}
.no-shipping .text-cont {
  text-align: left;
}
.no-shipping .support {
  display: inline-block;
}
.no-shipping .support a {
  font-weight: bold;
  color: var(--color-text);
}
</style>
