<script setup lang="ts">
import { reactive, watch } from "vue";
import NavigationBar from "@/components/layout/NavigationBar.vue";
import CartDetails from "@/components/CartDetails.vue";
import { RouterView } from "vue-router";
import BaseBottom from "@/components/layout/BaseBottom.vue";
import { useRoute } from "vue-router";
import { RoutePath } from "@/router/navigation-steps";

const route = useRoute();
const classes = reactive({
  order: false,
});

watch(
  () => route.path,
  (newPath: any) => {
    if (newPath === RoutePath.PAYMENT_DETAILS) classes.order = true;
    else classes.order = false;
  }
);
</script>

<template>
  <NavigationBar />

  <div class="grid-container">
    <main class="main-container">
      <RouterView v-slot="{ Component }">
        <transition name="slide-left" mode="out-in">
          <component :is="Component" />
        </transition>
      </RouterView>
    </main>
    <article class="cart-details-container" :class="{ order: classes.order }">
      <CartDetails />
    </article>
  </div>

  <BaseBottom />
</template>

<style scoped>
@import "@/assets/base.css";

.grid-container {
  display: grid;
  margin: 3em 0 3em 0;
}
.main-container {
  min-height: 60vh;
  max-width: 45rem;
  margin: 0 auto;
  width: 100%;
}
.cart-details-container {
  width: 100%;
  min-height: 60vh;
  max-width: 45rem;
  margin: 0 auto;
}

@media (--min-tablet-width) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
    column-gap: 8em;
  }
  .main-container {
    padding: 0 0 0 8em;
  }
  .cart-details-container {
    padding: 0 8em 0 0;
  }
}
@media (--max-laptop-width) {
  .grid-container {
    column-gap: 4em;
  }
}
@media (--max-tablet-width) {
  .grid-container {
    grid-template-rows: 1fr;
    row-gap: 4em;
  }
  .main-container,
  .cart-details-container {
    padding: 0 2.5em 0 2.5em;
  }
  .cart-details-container.order {
    order: -1;
  }
}

.slide-left-leave-active,
.slide-left-enter-active {
  transition: all 0.3s linear;
}
.slide-left-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-5em);
}
.slide-left-enter-to,
.slide-left-leave-from {
  transform: translateX(0em);
  opacity: 1;
}

@media (--max-phone-width) {
  .slide-left-enter-from,
  .slide-left-leave-to {
    transform: unset;
    opacity: 0;
  }
}
</style>
